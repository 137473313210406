import React from "react";
import { FormControl, Select, InputLabel, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// STYLE
import { styled } from "@mui/system";
import useStyles from "../../assets/styles/index";

const CustomFormControl = styled(FormControl)(({ theme, font, color }) => ({
  ".MuiInputLabel-formControl": {
    fontSize: 20,
    fontWeight: 700,
    fontFamily:
      font === "en" ? theme.typography.font.en : theme.typography.font.th,
    color: `${theme.palette.brown[800]} !important`,
    top: "50%",
    transform: "translate(1rem,-50%)",
    maxWidth: "70%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

const CustomSelect = styled(Select)(
  ({ theme, radius, font, color, value, type }) => ({
    color:
      color === "dark"
        ? theme.palette.navy
        : type === "calendar"
        ? theme.palette.dark[700]
        : theme.palette.brown[900],
    borderRadius: radius || 10,
    backgroundColor:
      type === "dark"
        ? theme.palette.dark[800]
        : type === "calendar"
        ? theme.palette.brown[300]
        : theme.palette.brown[200],
    fontSize: 20,
    fontWeight: 700,
    fontFamily:
      font === "en" ? theme.typography.font.en : theme.typography.font.th,
    ".MuiSelect-select": {
      padding: type === "calendar" ? "0 1rem" : "0.375rem 1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    svg: {
      fontSize: 30,
      fill:
        type === "dark"
          ? theme.palette.white["default"]
          : theme.palette.brown[900],
    },
    fieldset: {
      border: `2px solid ${
        type === "calendar"
          ? theme.palette.brown[600]
          : theme.palette.brown[900]
      } !important`,
    },
    "&.Mui-Select-root-access": {
      margin: "1px 0",
      fontSize: "20px !important",
      backgroundColor: value ? theme.palette.green : theme.palette.red,
      ".MuiSelect-select": {
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
      },
    },
  })
);

export default function BasicSelect(props) {
  const classes = useStyles();

  const {
    radius,
    option,
    onChange,
    value,
    labelId,
    id,
    placeholder,
    formStyle,
    font,
    color,
    selectClassName,
    theme,
    onClear,
    disabledClear,
  } = props;

  return (
    <CustomFormControl
      fullWidth
      size="small"
      font={font}
      style={{ ...formStyle }}
    >
      <InputLabel id={id}>{!value ? placeholder : ""}</InputLabel>
      <CustomSelect
        labelId={labelId}
        id={id}
        value={value}
        onChange={onChange}
        size="small"
        font={font}
        color={color}
        radius={radius}
        className={selectClassName}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        MenuProps={{ classes: { paper: classes.basicSelectStyle } }}
        type={theme}
      >
        {option}
        {/* <MenuItem value=''>None</MenuItem> */}
      </CustomSelect>
      {value && !disabledClear && (
        <Box className={"absolute top-1/2 -translate-y-1/2 right-8"}>
          <CloseIcon
            className={"lg:!text-2xl cursor-pointer"}
            onClick={onClear}
          />
        </Box>
      )}
    </CustomFormControl>
  );
}
