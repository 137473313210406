import { Grid } from '@mui/material';
import React from 'react';

// STYLE
import { styled } from '@mui/system';
import FooterLogo from '../../assets/images/footerLogo.png';

const FooterLine = styled('img')(({ theme }) => ({
  width: '500px',
  [theme.breakpoints.down('xxl')]: {
    width: '450px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '400px',
  },
}));

export default function Footer(props) {
  const { className } = props;

  return (
    <FooterLine
      src={FooterLogo}
      alt='footer'
      className={`${className} mx-auto`}
    />
  );
}
