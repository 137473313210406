import axios from '../configs/axios';

export const createVoucher = async (payload) => {
  try {
    const result = await axios.post('/voucher/create', payload);
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllVoucher = async () => {
  try {
    const result = await axios.get('/voucher/getAll');
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const usedVoucher = async (voucherNumber) => {
  try {
    const result = await axios.patch(`/voucher/${voucherNumber}/use`);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const validateVoucher = async (voucherNumber) => {
  try {
    const result = await axios(`/voucher/${voucherNumber}/validate`);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const downloadCsvVoucher = async () => {
  try {
    window.location.assign(
      'https://hallofinspiration.maefahluang.org/voucher/download'
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateVoucherStatus = async (voucherNumber, status) => {
  try {
    const result = await axios.patch(`/voucher/update-status`, {
      voucherNumber,
      status,
    });
    return result;
  } catch (err) {
    return err.response;
  }
};
