import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import PermissionRoutes from '../configs/PermissionRoutes';
import { checkToken } from '../helpers/LocalStorageService';

const RoutesConfig = () => {
  const allowedRouteComponent = PermissionRoutes['guest'];
  const [allowedComponent, setAllowedComponent] = useState(
    allowedRouteComponent
  );

  useEffect(() => {
    const role = checkToken();
    setAllowedComponent(PermissionRoutes[role]);
  }, []);

  return (
    <Routes>
      {allowedComponent?.map((router) => (
        <Route
          exact
          path={router?.url}
          key={router?.url || router?.page}
          element={
            <router.layout>
              <router.page />
            </router.layout>
          }
        />
      ))}
      {/* {conditionalRedirect()} */}
    </Routes>
  );
};

export default RoutesConfig;
