export const routePath = {
  home: '/',
  history: '/history',
  admin: '/admin',
  adminLogin: '/admin',
  hash: {
    home: '#home',
    about: '#about',
    visit: '#visit',
    history: '#history',
    contact: '#contact',
  },
  booking: {
    index: '/booking',
    form: '/booking/form',
    checkout: '/booking/checkout',
  },
  payment: {
    index: '/payment',
    form: '/payment/form',
    receipt: '/payment/receipt',
  },
  policy: '/policy',
};
