import { useState } from "react";
import _ from "lodash";

// COMPONENT
import CustomTable from "../../../components/table/CustomTable";
import TableRow from "../../../components/table/CustomTableRow";
import { useEffect } from "react";

export default function Booking(props) {
  const { data, setBookingId } = props;
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(20);
  const tableHead = [
    { id: "showDate", label: "Show date", align: "center", width: "" },
    { id: "showtime", label: "Showtime", align: "center", width: "" },
    { id: "name", label: "Name", align: "center", width: "" },
    { id: "ticketNumber", label: "Ticket number", align: "center", width: "" },
    { id: "ticketType", label: "Ticket type", align: "center", width: "" },
    {
      id: "visitingStatus",
      label: "Visiting status",
      align: "center",
      width: "",
    },
    { id: "orderDate", label: "Order date", align: "center", width: "" },
    { id: "action", label: "", align: "center", width: "" },
  ];

  useEffect(() => {
    setPage(1);
  }, [data]);

  const renderData = (data, index) => {
    const { date, time, name, ticket, ticketType, status, id, tax, orderDate } =
      data;

    const render = {
      columns: [
        { type: "date", align: "center", label: date || "-" },
        { type: "time", align: "center", label: time || "-" },
        { type: "text", align: "center", label: name || "-" },
        { type: "text", align: "center", label: ticket || "-" },
        { type: "text", align: "center", label: ticketType },
        { type: "status", align: "center", label: status },
        { type: "text", align: "center", label: orderDate },
        { type: "edit", label: id },
      ],
    };
    return (
      <TableRow
        className={`${tax ? "has-tax" : ""}`}
        {...render}
        key={index}
        onEdit={(id) => setBookingId(id)}
      />
    );
  };

  return (
    <CustomTable
      page={page}
      dataCount={data.length}
      setPage={(page) => setPage(page)}
      tableMinWidth={1020}
      tableHead={tableHead}
      tableBody={_.map(
        data.slice(
          (page - 1) * rowPerPage,
          (page - 1) * rowPerPage + rowPerPage
        ),
        (val, index) => {
          return renderData(val, index);
        }
      )}
    />
  );
}
