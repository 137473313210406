import { useState, useEffect } from "react";
import _ from "lodash";
import { Box, Grid } from "@mui/material";

// COMPONENT
import { Typo } from "../../../assets/styles/typography";
import ButtonPrimary from "../../../components/button/Primary";
import InputDate from "../../../components/field/InputDate";
import InputCount from "../../../components/field/InputCount";

// STYLE
import { styled } from "@mui/system";
import { createVoucher } from "../../../services/voucherApi";

const CustomModal = styled(Box)(({ theme }) => ({
  ".MuiFormControl-root": {
    ".MuiOutlinedInput-root": {
      height: "40px",
    },
    ".MuiOutlinedInput-input": {
      fontSize: "22px !important",
    },
  },
  ".input-viewer": {
    "> div": {
      maxWidth: "100%",
      boxShadow: "none",
      border: `2px solid ${theme.palette.brown[900]}`,
      borderRadius: "10px",
      ".MuiOutlinedInput-root": {
        backgroundColor: "transparent",
      },
      button: {
        width: "25%",
        "&:first-of-type": {
          borderRight: `2px solid ${theme.palette.brown[900]}`,
          borderRadius: 0,
        },
        "&:last-of-type": {
          borderLeft: `2px solid ${theme.palette.brown[900]}`,
          borderRadius: 0,
        },
      },
    },
  },
  ".btn-confirm": {
    width: "100%",
    maxWidth: "214px",
    "&:disabled": {
      opacity: 0.7,
    },
    "&:not(:disabled)": {
      backgroundColor: theme.palette.dark[800],
    },
  },
}));

export default function ModalAddVoucher(props) {
  const { setOpen, setVoucherList, voucherList } = props;
  const [expiredDate, setExpiredDate] = useState("");
  const [quantity, setQuantity] = useState(1);

  const handelSubmit = async () => {
    const resultCreate = await createVoucher({ expiredDate, quantity });
    voucherList.unshift(...resultCreate.result)
    setVoucherList(voucherList);
    setOpen(false);
  };

  return (
    <CustomModal
      className={"admin mx-auto bg-white rounded-lg"}
      sx={{ maxWidth: 463 }}
    >
      <Box
        className={"bg-dark-800 py-2 text-center rounded-tl-lg rounded-tr-lg"}
      >
        <Typo variant="h4" font="en" className={"text-white"}>
          Create voucher
        </Typo>
      </Box>

      <Box className={"p-4 sm:py-6 sm:px-8"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typo variant="h4" font="en" className={"text-dark-800 pt-2"}>
              Expire date
            </Typo>
            <InputDate
              value={expiredDate}
              setValue={(value) => setExpiredDate(value)}
              locale="en"
              disabledClear
            />
          </Grid>
          <Grid item xs={6}>
            <Typo variant="h4" font="en" className={"text-dark-800"}>
              Quantities
            </Typo>
            <Box className="input-viewer">
              <InputCount
                value={quantity}
                min={1}
                max={100}
                onChange={(e) => {
                  const min = 1;
                  const max = 100;
                  const value = e.target.value;
                  if (value <= min) setQuantity(min);
                  else if (value >= max) setQuantity(max);
                  else setQuantity(value);
                }}
                setValue={(value) => setQuantity(value)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={"block pt-2 pb-6 px-4 text-center"}>
        {/* <ButtonPrimary
          variant='outlined'
          label='ยกเลิก'
          font='th'
          classes={'!shadow-none !mr-2 flex-1'}
          onClick={() => setOpen(false)}
        /> */}
        <ButtonPrimary
          disabled={!expiredDate || !quantity}
          theme="dark"
          label="Confirm"
          font="th"
          classes={"!shadow-none btn-confirm flex-1"}
          onClick={handelSubmit}
        />
      </Box>
    </CustomModal>
  );
}
