import React from 'react';
import { Box, Modal } from '@mui/material';

// STYLE
import { styled } from '@mui/system';

const BasicModal = styled(Modal)(({ theme }) => ({
  '> div:nth-of-type(3)': {
    // width: '100%',
  },
}));

export default function CustomModal(props) {
  const { open, handleClose, body, maxWidth } = props;

  return (
    <BasicModal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={`flex items-center justify-center`}
    >
      <Box
        className={`w-full m-4`}
        style={{ maxWidth: maxWidth || 'max-content' }}
      >
        {body}
      </Box>
    </BasicModal>
  );
}
