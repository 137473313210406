import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Thai
import common_th from './locales/th/common.json';
import home_th from './locales/th/home.json';
import history_th from './locales/th/history.json';
import booking_th from './locales/th/booking.json';
import payment_th from './locales/th/payment.json';
import policy_th from './locales/th/policy.json';
import error_th from './locales/th/error.json';

// English
import common_en from './locales/en/common.json';
import home_en from './locales/en/home.json';
import history_en from './locales/en/history.json';
import booking_en from './locales/en/booking.json';
import payment_en from './locales/en/payment.json';
import policy_en from './locales/en/policy.json';
import error_en from './locales/en/error.json';

const resources = {
  th: {
    translation: {
      ...common_th,
      ...home_th,
      ...history_th,
      ...booking_th,
      ...payment_th,
      ...policy_th,
      ...error_th,
    },
  },
  en: {
    translation: {
      ...common_en,
      ...home_en,
      ...history_en,
      ...booking_en,
      ...payment_en,
      ...policy_en,
      ...error_en,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('doitung_locale') || 'th',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
