import axios from "../configs/axios";

export const getAllAccount = async () => {
  try {
    const result = await axios.get("/account/getAll");
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateStatusAccount = async (isStatus, id) => {
  try {
    const result = await axios.patch(`/account/update-status/${id}`, {
      isStatus,
    });
    return result.data;
  } catch (err) {
    console.log(err);
  }
};
