import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

// TRANSLATION
import { useTranslation } from "react-i18next";

const PrimaryButton = styled(Button)(
  ({ theme, font, section, variant, locale }) => ({
    height: 43,
    backgroundColor:
      variant === "outlined"
        ? "transparent"
        : section === "light"
        ? theme.palette.white["default"]
        : section === "dark"
        ? theme.palette.navy
        : theme.palette.brown[700],
    filter:
      section === "light"
        ? "none"
        : "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    boxShadow:
      section === "light"
        ? `0px 4px 4px ${theme.palette.black[25]}`
        : "none !important",
    borderRadius: 5,
    minWidth: 116,
    color:
      variant === "outlined"
        ? theme.palette.brown[700]
        : section === "light"
        ? theme.palette.brown[900]
        : theme.palette.brown[100],
    lineHeight: "normal !important",
    fontFamily: !font
      ? "inherit"
      : font === "th"
      ? theme.typography.font.th
      : theme.typography.font.en,
    border: "1px solid",
    borderColor:
      section === "light"
        ? theme.palette.white["default"]
        : section === "dark"
        ? theme.palette.navy
        : theme.palette.brown[700],
    "&:hover": {
      backgroundColor:
        variant === "outlined"
          ? "transparent"
          : section === "dark"
          ? theme.palette.navy
          : theme.palette.brown[900],
      borderColor: theme.palette.brown[900],
      color:
        variant === "outlined"
          ? theme.palette.brown[900]
          : theme.palette.white["default"],
    },
    "&.Mui-disabled": {
      backgroundColor:
        section === "dark" ? theme.palette.dark[800] : theme.palette.navy,
      borderColor:
        section === "dark" ? theme.palette.dark[800] : theme.palette.navy,
      color:
        section === "dark"
          ? theme.palette.white["default"]
          : theme.palette.brown[100],
    },
  })
);

export default function ButtonPrimary(props) {
  const { label, onClick, disabled, classes, font, style, theme, variant,icon } =
    props;

  const { i18n } = useTranslation();
  return (
    <PrimaryButton
      startIcon={icon}
      locale={i18n.language}
      variant={variant || "contained"}
      onClick={onClick}
      disabled={disabled}
      font={font}
      section={theme}
      className={`${
        i18n.language === "th"
          ? "!text-base sm:!text-xl !font-bold"
          : "!text-sm sm:!text-lg !font-medium"
      } !normal-case ${classes}`}
      style={{ ...style }}
    >
      {label}
    </PrimaryButton>
  );
}
