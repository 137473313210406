import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import PrimaryHeader from '../header/PrimaryHeader';
import ButtonPrimary from '../button/Primary';
import Footer from '../footer/Footer';
import CustomModal from '../../components/modal/CustomModal';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import background from '../../assets/images/visit/background.png';
import backgroundTitle from '../../assets/images/visit/background-title.png';
import ModalPublicUser from '../../pages/ModalPublicUser';

const ContentWrap = styled(Box)(({ theme }) => ({
  maxWidth: '540px',
  [theme.breakpoints.down('xxl')]: {
    maxWidth: '440px',
  },
}));

const TitleLabel = styled(Box)(({ theme }) => ({}));

export default function Visit() {
  const [openModel, setOpenModel] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.mainBackground} min-h-screen xxl:min-h-fit flex flex-col justify-center`}
    >
      <Box className={`${classes.container}`}>
        <PrimaryHeader title={t('HOME.SECTION_VISIT.TITLE')} />
      </Box>
      <Box
        className={
          'max-w-[990px] xxl:max-w-7xl w-full mx-auto mb-6 flex-grow flex justify-center items-center'
        }
      >
        <Grid spacing={2} container className="w-full" justifyContent="center">
          <Grid item md={6} className="w-full flex !flex-col">
            <Box className={'my-4 lg:mt-2 lg:mb-3 xxl:my-5'}>
              <Typo variant="h3" className={'font-medium text-center'}>
                {t('HOME.SECTION_VISIT.BODY.FIRST.TITLE')}
                <br />
                {t('HOME.SECTION_VISIT.BODY.FIRST.SUBTITLE')}
              </Typo>
            </Box>
            <ContentWrap
              className={`flex flex-col relative pt-7 px-5 pb-9 mx-auto h-full`}
            >
              <img
                src={background}
                alt="background"
                className={'absolute inset-0 w-full h-full object-fill'}
              />
              <TitleLabel className={'relative z-1 py-5'}>
                <img
                  src={backgroundTitle}
                  alt="background"
                  className={'absolute inset-0 w-full h-full object-fill'}
                />
                <Typo
                  variant="h3"
                  className={
                    'relative z-1 font-medium text-center text-white mb-6'
                  }
                  sx={{
                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  {t('HOME.SECTION_VISIT.BODY.FIRST.BODY.LABEL')}
                </Typo>
              </TitleLabel>
              <Box className={'relative z-1 mt-3 mb-4'}>
                <ul className={'text-2xl px-8'} style={{ listStyle: 'disc' }}>
                  <li>
                    <Typo
                      variant="h4"
                      className={`${
                        i18n.language === 'th' ? '!font-th400' : ''
                      }`}
                    >
                      {t('HOME.SECTION_VISIT.BODY.FIRST.BODY.LIST.LIST_1')}
                    </Typo>
                  </li>
                  <li>
                    <Typo
                      variant="h4"
                      className={`${
                        i18n.language === 'th' ? '!font-th400' : ''
                      }`}
                    >
                      {t('HOME.SECTION_VISIT.BODY.FIRST.BODY.LIST.LIST_2')}
                    </Typo>
                  </li>
                </ul>
              </Box>
              <Box className={'text-center mt-auto'}>
                <ButtonPrimary
                  label={t('BUTTON.CLICK_FOR_WATCH')}
                  classes={'lg:!text-2xl'}
                  style={{ minWidth: '170px' }}
                  onClick={() => setOpenModel(true)}
                />
              </Box>
            </ContentWrap>
          </Grid>
          <Grid item md={6} className="w-full flex !flex-col">
            <Box className={'my-4 lg:mt-2 lg:mb-3 xxl:my-5'}>
              <Typo variant="h3" className={'font-medium text-center'}>
                {t('HOME.SECTION_VISIT.BODY.SECOND.TITLE')}
                <br />
                {t('HOME.SECTION_VISIT.BODY.SECOND.SUBTITLE')}
              </Typo>
            </Box>
            <ContentWrap
              className={`flex flex-col relative pt-7 px-5 pb-9 mx-auto h-full`}
            >
              <img
                src={background}
                alt="background"
                className={'absolute inset-0 w-full h-full object-fill'}
              />
              <TitleLabel className={'relative z-1 py-5'}>
                <img
                  src={backgroundTitle}
                  alt="background"
                  className={'absolute inset-0 w-full h-full object-fill'}
                />
                <Typo
                  variant="h3"
                  className={
                    'relative z-1 font-medium text-center text-white mb-6'
                  }
                  sx={{
                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  {t('HOME.SECTION_VISIT.BODY.SECOND.BODY.LABEL')}
                </Typo>
              </TitleLabel>
              <Box className={'relative z-1 mt-3 mb-4'}>
                <ul className={'text-2xl px-8'} style={{ listStyle: 'disc' }}>
                  <li>
                    <Typo
                      variant="h4"
                      className={`${
                        i18n.language === 'th' ? '!font-th400' : ''
                      }`}
                    >
                      {t('HOME.SECTION_VISIT.BODY.SECOND.BODY.LIST.LIST_1')}
                    </Typo>
                  </li>
                  <li>
                    <Typo
                      variant="h4"
                      className={`${
                        i18n.language === 'th' ? '!font-th400' : ''
                      }`}
                    >
                      {t('HOME.SECTION_VISIT.BODY.SECOND.BODY.LIST.LIST_2')}
                    </Typo>
                  </li>
                  <li>
                    <Typo
                      variant="h4"
                      className={`${
                        i18n.language === 'th' ? '!font-th400' : ''
                      }`}
                    >
                      {t('HOME.SECTION_VISIT.BODY.SECOND.BODY.LIST.LIST_3')}
                    </Typo>
                  </li>
                </ul>
              </Box>
              <Box className={'text-center mt-auto'}>
                <ButtonPrimary
                  label={t('BUTTON.BOOKING')}
                  onClick={() => {
                    navigate(routePath.booking.index);
                  }}
                  classes={'lg:!text-2xl'}
                  style={{ minWidth: '170px' }}
                />
              </Box>
            </ContentWrap>
          </Grid>
        </Grid>
      </Box>
      <Footer className={`mt-auto`} />
      <CustomModal
        open={openModel}
        handleClose={(open) => setOpenModel(open)}
        maxWidth="563px"
        body={<ModalPublicUser setOpen={(open) => setOpenModel(open)} />}
      />
    </Box>
  );
}
