import { useState } from "react";
import _ from "lodash";

// COMPONENT
import CustomTable from "../../../components/table/CustomTable";
import TableRow from "../../../components/table/CustomTableRow";

import {
  getAllAccount,
  updateStatusAccount,
} from "../../../services/accountApi";

// DATA
import { useEffect } from "react";
import { changeLanguage } from "i18next";

export default function Account() {
  const [page, setPage] = useState(1);
  const [accountList, setAccountList] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(20);

  const fetchData = async () => {
    const resultAccount = await getAllAccount();
    setAccountList(resultAccount.result);
  };

  const handleUpdate = async (isStatus, id) => {
    await updateStatusAccount(isStatus, id).catch((err) => console.log(err));
    const newList = accountList.map((account) =>
      account.id === id ? { ...account, status: isStatus } : account
    );
    setAccountList(newList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableHead = [
    {
      id: "bookingNumber",
      label: "Booking number",
      align: "center",
      width: "",
    },
    { id: "ticketNumber", label: "Ticket number", align: "center", width: "" },
    { id: "access", label: "Access", align: "center", width: "" },
  ];

  const renderData = (data, index) => {
    const { bookingNumber, ticketNumber, status, id } = data;
    const render = {
      columns: [
        { type: "text", align: "center", label: bookingNumber || "-" },
        { type: "text", align: "center", label: ticketNumber || "-" },
        {
          id: id,
          type: "select",
          align: "center",
          label: status,
          option: [
            { value: true, label: "Opened" },
            { value: false, label: "Closed" },
          ],
        },
      ],
    };
    return (
      <TableRow
        {...render}
        key={index}
        onChange={(value, id) => handleUpdate(value, id)}
      />
    );
  };

  return (
    <CustomTable
      page={page}
      dataCount={accountList.length}
      setPage={(page) => setPage(page)}
      tableMinWidth={640}
      tableHead={tableHead}
      tableBody={_.map(
        accountList.slice(
          (page - 1) * rowPerPage,
          (page - 1) * rowPerPage + rowPerPage
        ),
        (val, index) => {
          return renderData(val, index);
        }
      )}
    />
  );
}
