import { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Tabs, Box, Typography } from '@mui/material';

// COMPONENT
import TableSearch from '../../components/admin/TableSearch';
import ButtonPrimary from '../../components/button/Primary';
import Booking from './Contents/Booking';
import BookingDetail from './Contents/BookingDetail';
import Calendar from './Contents/Calendar';
import Account from './Contents/Account';
import Voucher from './Contents/Voucher';
import DashBoard from './Contents/DashBoard';

// STYLE
import useStyles from '../../assets/styles';
import * as UseStyled from './UseStyled';
import { Typo } from '../../assets/styles/typography';
import { useEffect } from 'react';
import { downloadCsv, getUserAll } from '../../services/userApi';
import { datePattern } from '../../helpers/utils';
import { removeToken } from '../../helpers/LocalStorageService';
import { downloadCsvVoucher } from '../../services/voucherApi';
import { downloadCsvPublicUser } from '../../services/publicUserApi';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function AdminIndex() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [menus, setMenus] = useState([
    'Booking',
    'Calendar',
    'Account',
    'Voucher',
    'DashBoard',
    'Logout',
  ]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataDetailFilter, setDataDetailFilter] = useState([]);
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [dateDashBoard, setDateDashBoard] = useState('');
  const [status, setStatus] = useState('');
  const [filterBy, setFilterBy] = useState('createAt');
  const [bookingId, setBookingId] = useState(0);
  const [openCreateVoucher, setOpenCreateVoucher] = useState(false);

  const fetchDataUserBooking = async () => {
    const userList = await getUserAll();
    const newUserDataList = userList.result.map((data) => ({
      id: data.id,
      date: data.Booking.date,
      time: `${data.Booking.timeStartAt.slice(0, -3)} - ${data.Booking.timeEndAt.slice(0, -3)}`,
      name: data.name,
      ticket: data.ticketCode,
      ticketType: data.ticketType,
      orderDate: datePattern(data.createdAt),
      status: data.status,
      tax: data.isTax !== '0',
      paymentId: data.paymentId,
      bookingId: data.Booking.bookingId,
    }));
    setDataDetail(newUserDataList);
    setDataDetailFilter(newUserDataList);
  };

  const handleLogout = () => {
    removeToken();
    window.location.reload();
  };

  useEffect(() => {
    fetchDataUserBooking();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setBookingId(0);
  };

  function filterHandle(targetFilter) {
    let dataFilter;
    if (targetFilter !== '') {
      dataFilter = dataDetail.filter((data) => data.status === targetFilter);
    } else {
      dataFilter = dataDetail;
    }
    setDataDetailFilter(dataFilter);
  }
  const isCondition = (data, targetSearch) => {
    if (targetSearch === 'tax' || targetSearch === 'ภาษี') targetSearch = true;
    return (
      data.ticket.includes(targetSearch) ||
      data.name.includes(targetSearch) ||
      data.paymentId.includes(targetSearch) ||
      data.bookingId.includes(targetSearch) ||
      data.tax === targetSearch
    );
  };

  function searchHandle(targetSearch) {
    let dataFilter;
    if (targetSearch !== '') {
      dataFilter = dataDetail.filter((data) => isCondition(data, targetSearch));
    } else {
      dataFilter = dataDetail;
    }
    setDataDetailFilter(dataFilter);
  }

  function dateFilterHandle(targetSearch) {
    let dataFilter;
    if (targetSearch !== '') {
      dataFilter = dataDetail.filter((data) => {
        const date = moment(data.date);
        const startDate = moment(targetSearch.from);
        const endDate = moment(targetSearch.to);
        return moment(date).isBetween(startDate, endDate, 'day', '[]');
      });
    } else {
      dataFilter = dataDetail;
    }
    setDataDetailFilter(dataFilter);
  }

  return (
    <div
      className={`admin ${classes.secondBackground} ${classes.containerLayout} ${classes.mainLayout} bg-brown-100 bg-blend-multiply min-h-full h-auto pb-10 `}
    >
      <UseStyled.FragmentHeader
        className={`${classes.container} mx-auto mb-6 pt-8 sm:px-7 lg:px-4`}
      >
        <Typo variant="h2" font="en" className={'!font-bold !font-th text-center !mb-8'}>
          {bookingId > 0 ? 'Information' : menus[tab]}
        </Typo>
        {tab === 0 && !bookingId && (
          <TableSearch
            filterHandle={filterHandle}
            searchHandle={searchHandle}
            dateFilterHandle={dateFilterHandle}
            formClasses="lg:w-4/5 lg:ml-auto hidden lg:flex"
            search={search}
            date={date}
            status={status}
            setSearch={(search) => setSearch(search)}
            setDate={(date) => setDate(date)}
            setStatus={(status) => setStatus(status)}
            styleSearch={{ maxWidth: 400 }}
            styleDate={{ maxWidth: 245 }}
            styleStatus={{ maxWidth: 265 }}
            menuList={[
              { name: 'Booked', value: 'booked' },
              { name: 'Visited', value: 'visited' },
              { name: 'Expired', value: 'expired' },
            ]}
          />
        )}
        {tab === 4 && (
          <TableSearch
            formClasses="lg:w-4/5 lg:ml-auto hidden lg:flex"
            date={dateDashBoard}
            setDate={(date) => setDateDashBoard(date)}
            status={filterBy}
            setStatus={(filterBy) => setFilterBy(filterBy)}
            styleDate={{ width: 500 }}
            styleStatus={{ width: 300, order: -1 }}
            isSearchKey={false}
            menuList={[
              { name: 'Create Date', value: 'createAt' },
              { name: 'Booking Date', value: 'date' },
            ]}
          />
        )}
      </UseStyled.FragmentHeader>
      <UseStyled.FragmentBody
        className={`${classes.container} mx-auto flex flex-col lg:flex-row sm:px-7 lg:px-4`}
      >
        <UseStyled.FragmentLeft className="w-full flex-none lg:w-1/5 xxl:w-1/6">
          <Box>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              classes={{
                flexContainer:
                  'block text-center lg:flex items-center justify-between md:justify-center lg:flex-col overflow-y-auto',
              }}
              TabIndicatorProps={{
                style: { height: 0 },
              }}
            >
              {_.map(menus, (menu, index) => {
                return (
                  <UseStyled.ButtonTab
                    key={menu}
                    label={menu}
                    onClick={() => (menu === 'Logout' ? handleLogout() : setBookingId(0))}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </Box>
        </UseStyled.FragmentLeft>
        <UseStyled.FragmentRight className="w-full">
          {_.map(menus, (menu, index) => {
            return (
              <TabPanel key={index} value={tab} index={index}>
                {tab === 0 && !bookingId && (
                  <UseStyled.CustomTableSearch className="block md:mt-4 lg:hidden">
                    <TableSearch
                      filterHandle={filterHandle}
                      searchHandle={searchHandle}
                      dateFilterHandle={dateFilterHandle}
                      formClasses="flex flex-wrap justify-between md:flex-nowrap md:justify-center lg:hidden"
                      search={search}
                      date={date}
                      status={status}
                      setSearch={(search) => setSearch(search)}
                      setDate={(date) => setDate(date)}
                      setStatus={(status) => setStatus(status)}
                      styleSearch={{ maxWidth: 400 }}
                      styleDate={{ maxWidth: 245 }}
                      styleStatus={{ maxWidth: 265 }}
                    />
                  </UseStyled.CustomTableSearch>
                )}

                <UseStyled.BodyBox type={menu} className={'mt-6 lg:mt-0'}>
                  {tab === 3 && (
                    <Box className="text-right">
                      <ButtonPrimary
                        theme="light"
                        label="Create voucher"
                        font="th"
                        classes={
                          '!text-lg lg:!text-2xl sm:w-32 lg:w-44 !shadow-none !border !border-solid !border-brown-900 !mb-4'
                        }
                        onClick={() => setOpenCreateVoucher(true)}
                      />
                    </Box>
                  )}
                  <UseStyled.BodyBoxBody type={menu}>
                    {menu === 'Calendar' ? (
                      <Calendar />
                    ) : menu === 'Account' ? (
                      <Account />
                    ) : menu === 'Voucher' ? (
                      <Voucher
                        open={openCreateVoucher}
                        onClose={(open) => setOpenCreateVoucher(open)}
                      />
                    ) : menu === 'DashBoard' ? (
                      <DashBoard dateDashBoard={dateDashBoard} filterBy={filterBy} />
                    ) : (
                      <>
                        {(bookingId > 0 && <BookingDetail bookingId={bookingId} />) || (
                          <Booking
                            data={dataDetailFilter}
                            setBookingId={(id) => setBookingId(id)}
                          />
                        )}
                      </>
                    )}
                  </UseStyled.BodyBoxBody>
                  {tab === 0 && (
                    <Typography
                      className={classes.subTitleBooking}
                      variant="subtitle1"
                      gutterBottom
                    >
                      *หมายเหตุ ชื่อที่เป็นสีฟ้าหมายถึงบุคคลที่ขอออกใบกำกับภาษี
                    </Typography>
                  )}
                  {(tab === 0 || tab === 3 || tab === 4) && !bookingId && (
                    <Box className={'text-right mt-8'}>
                      {tab === 4 && (
                        <ButtonPrimary
                          onClick={() => downloadCsvPublicUser(dateDashBoard)}
                          theme="light"
                          label="Export Public List"
                          font="th"
                          classes={'!text-lg lg:!text-2xl sm:w-32 lg:w-44 !mr-5'}
                        />
                      )}
                      <ButtonPrimary
                        onClick={
                          tab === 0
                            ? downloadCsv
                            : tab === 4
                            ? () => downloadCsv(dateDashBoard, filterBy)
                            : tab === 3
                            ? downloadCsvVoucher
                            : () => {}
                        }
                        theme="light"
                        label="Export CSV"
                        font="th"
                        classes={'!text-lg lg:!text-2xl sm:w-32 lg:w-44'}
                      />
                    </Box>
                  )}
                </UseStyled.BodyBox>
              </TabPanel>
            );
          })}
        </UseStyled.FragmentRight>
      </UseStyled.FragmentBody>
    </div>
  );
}
