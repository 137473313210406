import React from 'react';
import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../../../assets/styles/typography';
import CustomRadio from '../../../components/field/Radio';
import ButtonPrimary from '../../../components/button/Primary';

// ROUTE
import { routePath } from '../../../configs/route';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../../assets/styles';
import CreditImg from '../../../assets/images/payment/credit_card.png';
import BankingImg from '../../../assets/images/payment/internet_banking.png';

export default function BankingMethods(props) {
  const { data } = props;
  const { i18n, t } = useTranslation();

  return <div>Banking</div>;
}
