import React from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// COMPONENT
import InputTextField from './InputTextField';

const FormControl = styled(Box)(({ theme }) => ({
  height: 40.75,
  boxShadow: `0px 4px 4px ${theme.palette.black[25]}`,
  '.MuiOutlinedInput-input': {
    textAlign: 'center',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &[type=number]':
      {
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
        margin: 0,
      },
  },
  '.MuiOutlinedInput-root': {
    boxShadow: 'none !important',
  },
  '&.field-disabled': {
    pointerEvents: 'none',
    backgroundColor: theme.palette.dark[200],
    '.MuiOutlinedInput-root': {
      opacity: 0.7,
      backgroundColor: theme.palette.dark[200],
    },
    '.MuiButton-root': {
      opacity: 0.7,
    },
  },
  [theme.breakpoints.down('sm')]: {
    height: 35,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  padding: '0 10px',
  minWidth: 'unset',
  height: '100%',
}));

export default function InputCount(props) {
  const { maxWidth, min, max, value, setValue, onChange, disabled } = props;

  return (
    <FormControl
      className={`flex items-center bg-white rounded ${
        disabled ? 'field-disabled' : ''
      }`}
      sx={{ maxWidth: maxWidth || '130px', height: 'auto' }}
    >
      <ActionButton
        onClick={() => {
          if (value <= min) setValue(min);
          else setValue(value - 1);
        }}
      >
        <RemoveIcon className="!text-base" />
      </ActionButton>
      <InputTextField type="number" value={value} onChange={onChange} />
      <ActionButton
        onClick={() => {
          if (value >= max) setValue(max);
          else setValue(value + 1);
        }}
      >
        <AddIcon className="!text-base" />
      </ActionButton>
    </FormControl>
  );
}
