import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import BankingMethods from './methods/Banking';
import CreditMethods from './methods/Credit';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';

export default function PaymentForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const classes = useStyles();

  const [form, setForm] = useState({});
  const [method, setMethod] = useState('');

  useEffect(() => {
    if (_.isEmpty(location.state)) {
      navigate(routePath.booking.index);
    } else {
      setForm(location.state.data);
      setMethod(location.state.method);
    }
  }, [location]);

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.secondBackground} bg-brown-100 bg-blend-multiply min-h-screen flex flex-col items-center pb-8`}
      locale={i18n.language}
    >
      <Box
        className='w-full pt-12 md:pt-28 lg:pt-12'
        sx={{ maxWidth: '1084px' }}
      >
        <Typo
          variant='h2'
          className={`header !font-medium !text-3xl sm:!text-[40px]`}
        >
          {t('PAYMENT.TITLE')}
        </Typo>
        {(method === 'credit' && <CreditMethods data={form} />) || (
          <BankingMethods data={form} />
        )}
      </Box>
    </Box>
  );
}
