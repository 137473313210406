import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Box, IconButton, SwipeableDrawer } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// CONSTANT
import { navbar } from '../../constant';

// COMPONENT
import MainLogo from '../logo/MainLogo';
import Translation from '../button/Translation';

// STYLE
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/images/logo.png';
import { LandingNavbar } from '../../assets/styles/navbar';
import useStyles from '../../assets/styles';

export default function LandingHeader(props) {
  const { position } = props;
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <nav className={'bg-white'}>
      <LandingNavbar className={`w-full px-4 md:px-6 border-8 border-solid`}>
        <Box
          className={`${classes.container} flex items-center mx-auto w-full h-full`}
        >
          <HashLink to={`/${navbar[0].path}`} id="name-link" smooth>
            <MainLogo responsive="true" />
          </HashLink>
          <Box className={'hidden lg:block ml-auto'}>
            {_.map(navbar, (nav, index) => {
              return (
                index !== 1 && (
                  <HashLink
                    key={index}
                    to={`/${nav.path}`}
                    smooth
                    className={`lg:px-6 xl:px-8 ${
                      i18n.language === 'th' ? 'text-3xl' : 'text-2xl'
                    } font-medium`}
                    style={{ lineHeight: '25px' }}
                  >
                    {t(nav.name)}
                  </HashLink>
                )
              );
            })}
          </Box>

          <Box className={`lg:hidden ml-auto`}>
            <IconButton onClick={toggleDrawer('left', true)}>
              <MenuIcon className={'!text-xl'} />
            </IconButton>

            <SwipeableDrawer
              anchor={'left'}
              open={open}
              onClose={toggleDrawer('left', false)}
              onOpen={toggleDrawer('left', true)}
              sx={{
                '.MuiPaper-root': {
                  width: '50%',
                  maxWidth: '300px',
                  paddingTop: '16px',
                },
              }}
            >
              {_.map(navbar, (nav, index) => {
                return (
                  index !== 1 && (
                    <HashLink
                      key={index}
                      to={`/${nav.path}`}
                      smooth
                      className={`block px-3 my-1 ${
                        i18n.language === 'th'
                          ? 'text-lg md:text-2xl'
                          : 'text-base md:text-xl'
                      } font-medium`}
                      onClick={toggleDrawer('left', false)}
                    >
                      {t(nav.name)}
                    </HashLink>
                  )
                );
              })}
            </SwipeableDrawer>
          </Box>
          <Box className={'pl-2 sm:pl-4 lg:pl-6 xl:pl-8'}>
            <Translation />
          </Box>
        </Box>
      </LandingNavbar>
    </nav>
  );
}
