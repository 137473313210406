import axios from '../configs/axios';
import moment from 'moment';

export const userPublicAccess = async (email, age, country) => {
  try {
    const result = await axios.post('/userPublic/create', {
      email,
      age,
      country,
    });
    return result.data.isSuccess;
  } catch (err) {
    console.log(err);
  }
};

export const downloadCsvPublicUser = async ({ from, to }) => {
  try {
    if (from && to) {
      const dateFrom = moment(from).format('YYYY-M-DD');
      const dateTo = moment(to).format('YYYY-M-DD');
      window.location.assign(
        `https://hallofinspiration.maefahluang.org/userPublic/download?dateFrom=${dateFrom}&dateTo=${dateTo}`
      );
    } else {
      window.location.assign(
        `https://hallofinspiration.maefahluang.org/userPublic/download`
      );
    }
  } catch (err) {
    console.log(err);
  }
};
