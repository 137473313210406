import { createTheme } from "@mui/material/styles";

import palette from "./palette";
import scale from "./scale";
import typography from "./typography";

const THEME = createTheme({
  overrides: {},
  typography,
  palette,
  scale,
  breakpoints: {
    values: {
      xs: 0, // default
      sm: 600, // default
      md: 900, // default
      lg: 1200, // default
      xl: 1536, // default
      xxl: 1920,
    },
  },
});

export default THEME;
