import React from 'react';
import { Box, Grid, Link } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import PrimaryHeader from '../header/PrimaryHeader';
import Footer from '../footer/Footer';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import CallIcon from '@mui/icons-material/Call';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

const Maps = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  paddingBottom: '56.25%',
  height: 0,
  marginTop: '1em',
  iframe: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
  },
}));

export default function Contact() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.mainBackground} min-h-screen xxl:min-h-fit pb-7`}
      sx={{ flexGrow: 1 }}
    >
      <Box className={`${classes.container} mt-8 mb-12 md:mb-16 lg:mb-12`}>
        <PrimaryHeader title={t('HOME.SECTION_CONTACT.TITLE')} />
      </Box>
      <Box className="max-w-[1080px] xxl:max-w-7xl mx-auto w-full lg:mb-auto">
        <Grid container spacing={10}>
          <Grid item md={6} className="box-border w-full h-full p-3">
            <Box className="flex items-center justify-center pb-5">
              <Typo variant="h3" className="drop-shadow">
                {t('HOME.SECTION_CONTACT.DOITUNG.TITLE')}
              </Typo>
            </Box>
            <Box className="flex mt-2">
              <LocationOnIcon className="!text-3xl mt-1" />
              <Typo variant="h5" className="pl-5">
                {t('HOME.SECTION_CONTACT.DOITUNG.BODY.ADDRESS')}
              </Typo>
            </Box>
            <Box className="flex items-center mt-2 lg:mt-0">
              <CallIcon className="!text-3xl" />
              <Typo variant="h5" className="pl-5">
                <a href="tel:+6622527114">02-252-7114</a>
              </Typo>
            </Box>
            <Box className="flex items-center mt-2">
              <LanguageIcon className="!text-3xl mt-1" />
              <Typo variant="h5" className="pl-5">
                <Link href="https://maefahluang.org">
                  https://maefahluang.org
                </Link>
              </Typo>
            </Box>
            <Maps>
              <iframe
                title="Doi Tung"
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.8710171143393!2d100.5434608147488!3d13.726257890363943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f246fb70131%3A0x596081ac0b821260!2z4Lih4Li54Lil4LiZ4Li04LiY4Li04LmB4Lih4LmI4Lif4LmJ4Liy4Lir4Lil4Lin4LiHIOC5g-C4meC4nuC4o-C4sOC4muC4o-C4oeC4o-C4suC4iuC4ueC4m-C4luC4seC4oeC4oOC5jA!5e0!3m2!1sth!2sth!4v1663434864737!5m2!1s${i18n.language}!2s${i18n.language}`}
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Maps>
          </Grid>

          <Grid item md={6} className="box-border w-full h-full p-3">
            <Box className="flex items-center justify-center pb-5">
              <Typo variant="h3" className="drop-shadow">
                {t('HOME.SECTION_CONTACT.HALL_OF_INSPIRE.TITLE')}
              </Typo>
            </Box>
            <Box className="flex mt-2">
              <LocationOnIcon className="!text-3xl mt-1" />
              <Typo variant="h5" className="pl-5">
                {t('HOME.SECTION_CONTACT.HALL_OF_INSPIRE.BODY.ADDRESS')}
              </Typo>
            </Box>
            <Box className="flex items-center mt-2 lg:mt-0">
              <CallIcon className="!text-3xl" />
              <Typo variant="h5" className="pl-5">
                0-5376-7015-7, Fax 0-5376-7077
              </Typo>
            </Box>
            <Box className="flex items-center mt-2">
              <LanguageIcon className="!text-3xl mt-1" />
              <Typo variant="h5" className="pl-5">
                <Link href="https://hallofinspiration.maefahluang.org">
                  https://hallofinspiration.maefahluang.org
                </Link>
              </Typo>
            </Box>
            <Maps>
              <iframe
                title="The Hall of Inspiration"
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.239670081283!2d99.80868251532476!3d20.29034441794982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30d6f0d51e540151%3A0x3535eb23925c554!2sThe%20Hall%20of%20Inspiration!5e0!3m2!1sen!2sth!4v1655624017506!5m2!1s${i18n.language}!2s${i18n.language}`}
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading={'lazy'}
                referrerPolicy={'no-referrer-when-downgrade'}
              ></iframe>
            </Maps>
          </Grid>
        </Grid>
      </Box>
      <Footer className={`mt-12 md:mt-16 lg:mt-8`} />
    </Box>
  );
}
