import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Chip } from '@mui/material';

// CALENDAR
import { MonthlyBody, MonthlyDay, MonthlyCalendar, MonthlyNav } from '@zach.codes/react-calendar';
import '../../../assets/styles/css/calendar.css';

// DATE FNS
import { startOfMonth } from 'date-fns';

// COMPONENT
import ButtonPrimary from '../../../components/button/Primary';
import CustomModal from '../../../components/modal/CustomModal';
import ModalAddRounds from './ModalAddRounds';
import ModalDeleteSubmit from './ModalDeleteSubmit';

// STYlE
import { styled } from '@mui/system';
import EditPencil from '../../../assets/icons/EditPencil';
import { allBookingData } from '../../../services/bookingApi';

const Event = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.brown[300],
  border: `1px solid ${theme.palette.brown[600]}`,
  height: '24px',
  svg: {
    position: 'absolute',
    right: '16px',
  },
  '.MuiChip-label': {
    fontSize: '14px',
    fontFamily: theme.typography.font.roboto,
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.dark[700],
  },
  '&:hover': {
    backgroundColor: theme.palette.brown[200],
  },
}));

export default function Calendar() {
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState([]);
  const [bookingTarget, setBookingTarget] = useState('');
  const [showData, setShowData] = useState([]);
  const [isFetch, setIsFetch] = useState(true);
  const [isCreate, setIsCreate] = useState(true);
  const [errorCode, setErrorCode] = useState('');

  const fetchDataAllBooking = async () => {
    const resultAllList = await allBookingData();
    const bookingAll = resultAllList.result;
    const newList = [];
    for (const booking in bookingAll) {
      const bookingList = bookingAll[booking].map((data) => ({
        bookingId: data.bookingId,
        round: {
          from: data.timeStartAt.slice(0, -3),
          to: data.timeEndAt.slice(0, -3),
        },
        language: data.languages,
        date: booking,
        viewer: data.viewer,
        status: data.status ? 'opened' : 'closed',
      }));
      newList.push({
        date: new Date(booking),
        data: bookingList,
      });
    }
    setShowData(newList);
  };

  useEffect(() => {
    if (!open) setData([]);
  }, [open]);

  useEffect(() => {
    fetchDataAllBooking();
  }, [isFetch]);

  // MontlyNav with year (CHEAT)
  useEffect(() => {
    const monthlyNavTextElem = document.querySelector('.monthly-nav > div > div');
    monthlyNavTextElem.textContent = `${currentMonth.toLocaleString('default', {
      month: 'long',
    })} ${currentMonth.getFullYear()}`;
  }, [currentMonth]);

  return (
    <>
      <MonthlyCalendar
        // locale={th}
        currentMonth={currentMonth}
        onCurrentMonthChange={(date) => setCurrentMonth(date)}
      >
        <div className={'monthly-nav'}>
          <MonthlyNav />
          <ButtonPrimary
            theme="light"
            label="Create show date & time"
            font="th"
            classes={'!text-lg lg:!text-2xl !shadow-none !border !border-solid !border-brown-900'}
            onClick={() => {
              setOpen(true);
              setIsCreate(true);
            }}
          />
        </div>
        <div className={'monthly-body'}>
          <MonthlyBody events={showData}>
            <MonthlyDay
              renderDay={(data) =>
                _.map(data, (item, index) => (
                  <div key={index} className={'day-event'}>
                    {item.title}
                    {_.map(item.data, (rd, indexData) => {
                      return (
                        <Event
                          key={indexData}
                          label={`${rd.round.from} - ${rd.round.to}`}
                          className="w-full !mb-1"
                          onClick={() => {
                            setOpen(true);
                            setData(rd);
                            setIsCreate(false);
                          }}
                          icon={<EditPencil />}
                        />
                      );
                    })}
                  </div>
                ))
              }
            />
          </MonthlyBody>
        </div>
      </MonthlyCalendar>
      <CustomModal
        open={open}
        handleClose={(open) => setOpen(open)}
        maxWidth="463px"
        body={
          <ModalAddRounds
            isCreate={isCreate}
            setOpen={(open) => setOpen(open)}
            setBookingTarget={setBookingTarget}
            setOpenDelete={(openDelete) => setOpenDelete(openDelete)}
            data={data}
            setIsFetch={setIsFetch}
            isFetch={isFetch}
            errorCode={errorCode}
            setErrorCode={setErrorCode}
          />
        }
      />
      <CustomModal
        open={openDelete}
        handleClose={(open) => setOpenDelete(open)}
        maxWidth="463px"
        body={
          <ModalDeleteSubmit
            bookingTarget={bookingTarget}
            setOpenDelete={(openDelete) => setOpenDelete(openDelete)}
            setOpen={(open) => setOpen(open)}
            dataCalendar={showData}
            setDataCalendar={setShowData}
            setErrorCode={setErrorCode}
          />
        }
      />
    </>
  );
}
