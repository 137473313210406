import React from "react";
import { Box } from "@mui/material";

// TRANSLATION
import { useTranslation } from "react-i18next";

// COMPONENT
import { Typo } from "../../assets/styles/typography";

// STYLE
import { styled } from "@mui/system";
import header from "../../assets/images/header.png";

const HeaderLine = styled("img")(({ theme }) => ({
  width: "360px",
  [theme.breakpoints.down("xxl")]: {
    width: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60%",
    maxWidth: "250px",
  },
}));

export default function PrimaryHeader(props) {
  const { title, className, isShow = true } = props;
  const { i18n } = useTranslation();
  return (
    <Box className={`font-${i18n.language}`}>
      <Typo
        variant="h2"
        className={`!font-medium text-center !text-3xl sm:!text-[38px] xxl:!text-[44px] ${className}`}
      >
        {title}
      </Typo>
      {isShow && (
        <HeaderLine
          src={header}
          alt="header"
          className="mx-auto mt-2 xxl:mt-4"
        />
      )}
    </Box>
  );
}
