import { makeStyles } from '@mui/styles';
import mainBackground from '../images/background.png';
import secondBackground from '../images/secondBackground.png';
import thirdBackground from '../images/thirdBackground.png';

import firstSectionBackground from '../images/home/background.png';

const useStyles = makeStyles((theme) => ({
  // layout
  fullHeight: {
    height: `100vh`,
    maxHeight: `100vh`,
  },
  mainLayout: {
    paddingTop: theme.scale.navbar,
    [theme.breakpoints.down('lg')]: {
      paddingTop: `calc(${theme.scale.navbar} - 20px)`,
    },
  },
  container: {
    maxWidth: 1920,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  containerLayout: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
  },

  // background
  mainBackground: {
    backgroundImage: `url(${mainBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.nav,
  },
  secondBackground: {
    backgroundImage: `url(${secondBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  thirdBackground: {
    backgroundImage: `url(${thirdBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  // select
  basicSelectStyle: {
    '& .MuiList-root': {
      '& .MuiMenuItem-root': {
        fontFamily: theme.typography.font.th,
        fontSize: 20,
        '&[aria-selected="true"]': {
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          opacity: 0.6,
        },
        '&:hover': {
          backgroundColor: theme.palette.brown[100],
        },
      },
    },
  },

  firstSectionBackground: {
    backgroundImage: `url(${firstSectionBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'grayscale(40%)',
  },
  loginModal: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(11),
      boxShadow: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(11),
    },
  },
  subTitleBooking: {
    fontSize: '1.2rem !important',
    margin: '10px !important',
    fontWeight: '600 !important',
  },
}));

export default useStyles;
