import { useState } from 'react';
import _ from 'lodash';
import { Box, Grid, MenuItem, TextField, Select } from '@mui/material';
import countries from 'i18n-iso-countries';

// COMPONENT
import { Typo } from '../assets/styles/typography';
import ButtonPrimary from '../components/button/Primary';
import bgModal from '../assets/images/bg-modal.png';

// STYLE
import { styled } from '@mui/system';
import { userPublicAccess } from '../services/publicUserApi';
import PrimaryHeader from '../components/header/PrimaryHeader';
import { useTranslation } from 'react-i18next';

// Import the languages you want to use
import enLocale from 'i18n-iso-countries/langs/en.json';
import thLocale from 'i18n-iso-countries/langs/th.json';

// Have to register the languages you want to use
countries.registerLocale(enLocale);
countries.registerLocale(thLocale);

const CustomBody = styled(Box)(({ theme }) => ({
  maxWidth: '528px',
  minHeight: '235px',
  borderRadius: '10px',
  backgroundImage: `url(${bgModal})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}));

const CustomTextFieldFull = styled(TextField)(({ theme, font, color }) => ({
  input: {
    backgroundColor: '#fff',
    width: '270px',
    maxWidth: '270px',
    fontSize: 20,
    fontWeight: 700,
    padding: '0.375rem 1rem',
    fontFamily: theme.typography.font[font],
    color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
    textOverflow: 'ellipsis',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.brown[800],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  fieldset: {
    border: `0px solid !important`,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
}));
const CustomTextFieldHaft = styled(TextField)(({ theme, font, color }) => ({
  input: {
    backgroundColor: '#fff',
    width: '140px',
    maxWidth: '270px',
    fontSize: 20,
    fontWeight: 700,
    padding: '0.375rem 1rem',
    fontFamily:
      font === 'en' ? theme.typography.font.en : theme.typography.font.th,
    color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
    textOverflow: 'ellipsis',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.brown[800],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  fieldset: {
    border: `0px solid !important`,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
}));

const CustomTextFieldSelectHaft = styled(Select)(({ theme, font, color }) => ({
  '.MuiSelect-select': {
    backgroundColor: '#fff',
    width: '126px',
    maxWidth: '270px',
    fontSize: 20,
    fontWeight: 700,
    padding: '0.375rem 1rem',
    fontFamily:
      font === 'en' ? theme.typography.font.en : theme.typography.font.th,
    color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
    textOverflow: 'ellipsis',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.brown[800],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  fieldset: {
    border: `0px solid !important`,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
}));

export default function ModalPublicUser(props) {
  const { setOpen } = props;
  const [age, setAge] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const { t, i18n } = useTranslation();

  const countryObj = countries.getNames('en', { select: 'official' });

  const countryArr = Object.entries(countryObj)
    .map(([key, value]) => ({
      label: value,
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleSubmit = async () => {
    const isSuccess = await userPublicAccess(email, age, country);
    if (isSuccess) {
      window.location.replace(
        'https://hallofinspiration.maefahluang.org/public/'
      );
    }
  };

  return (
    <CustomBody
      className={'admin mx-auto bg-white rounded-lg'}
      sx={{ maxWidth: 563 }}
    >
      <Grid className="py-4" justifyContent="center" alignItems="center">
        <PrimaryHeader
          isShow={false}
          title={t('HOME.SECTION_VISIT.GUEST_FORM.TITLE')}
        />
      </Grid>
      <Box className={'p-4 sm:py-6 sm:px-8'}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={'mb-3'}
        >
          <Grid
            item
            xs={12}
            md={3}
            className={['!pr-3', `font-${i18n.language}`]}
          >
            <Typo variant="h4" className={'text-dark-800'}>
              {t('HOME.SECTION_VISIT.GUEST_FORM.EMAIL')}
            </Typo>
          </Grid>
          <Grid item xs={12} md={7} className={'!pr-3'}>
            <CustomTextFieldFull
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={'mb-3'}
        >
          <Grid
            item
            xs={12}
            md={3}
            className={['!pr-3', `font-${i18n.language}`]}
          >
            <Typo variant="h4" className={'text-dark-800'}>
              {t('HOME.SECTION_VISIT.GUEST_FORM.AGE')}
            </Typo>
          </Grid>
          <Grid item xs={12} md={7} className={'!pr-3'}>
            <CustomTextFieldHaft
              type="number"
              onChange={(e) => setAge(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={'mb-3'}
        >
          <Grid
            item
            xs={12}
            md={3}
            className={['!pr-3', `font-${i18n.language}`]}
          >
            <Typo variant="h4" className={'text-dark-800'}>
              {t('HOME.SECTION_VISIT.GUEST_FORM.COUNTRY')}
            </Typo>
          </Grid>
          <Grid item xs={12} md={7} className={'!pr-3'}>
            <CustomTextFieldSelectHaft
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {!!countryArr?.length &&
                countryArr.map((option) => (
                  <MenuItem
                    className="custom-select"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
            </CustomTextFieldSelectHaft>
          </Grid>
        </Grid>
      </Box>

      <Box className={'block pt-2 pb-6 px-4 text-center'}>
        <ButtonPrimary
          variant="contained"
          label={t('BUTTON.CANCEL')}
          font={i18n.language}
          classes={'!shadow-none btn-cancel !mr-6 flex-1'}
          onClick={() => setOpen(false)}
        />
        <ButtonPrimary
          disabled={!country || !email || !age}
          variant="contained"
          label={t('BUTTON.SUBMIT')}
          font={i18n.language}
          classes={'!shadow-none btn-confirm !ml-6 flex-1'}
          onClick={handleSubmit}
        />
      </Box>
    </CustomBody>
  );
}
