import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import MainLogo from '../logo/MainLogo';
import SecondLogo from '../logo/SecondLogo';
import Translation from '../button/Translation';

// STYLE
import { Navbar } from '../../assets/styles/navbar';
import { useLocation } from 'react-router-dom';
import useStyles from '../../assets/styles';

export default function Header() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [hideTranslate, setHideTranslate] = useState(false);

  useEffect(() => {
    const path = pathname.substring(1).split('/');
    if (path[0] === 'admin') setHideTranslate(true);
  }, [pathname]);

  return (
    <nav className={'bg-brown-100'}>
      <Navbar className={`px-4 md:px-6 w-full`}>
        <Box
          className={`${classes.container} flex items-center justify-between mx-auto w-full h-full`}
        >
          <Link to={routePath.home} id="name-link">
            <MainLogo responsive="true" />
          </Link>
          <SecondLogo className={'ml-auto hidden sm:block'} responsive="true" />
          <div
            className={`items-center justify-between ml-4 ${
              hideTranslate ? 'hidden' : 'flex'
            }`}
          >
            <Translation />
          </div>
        </Box>
      </Navbar>
    </nav>
  );
}
