import React from 'react';

// STYLE
import doitungLogo from '../../assets/images/doitungLogo.png';
import { styled } from '@mui/system';

const Image = styled('img')(({ theme, width, responsive }) => ({
  [theme.breakpoints.down('lg')]: {
    width: responsive ? `calc(${width} - 30px)` : width,
  },
}));

export default function MainLogo(props) {
  const { size, responsive, className } = props;
  return (
    <Image
      src={doitungLogo}
      alt={'Doitung Logo'}
      responsive={responsive}
      width={size || '120px'}
      className={className}
    />
  );
}
