const bookingConfig = {
  ticketPrice: {
    NORMAL: 150,
    PRIVATE: 1500,
    VOUCHER: 0,
  },
  private: {
    requiredTickets: 20,
  },
};

export default bookingConfig;
