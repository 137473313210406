import { styled } from '@mui/system';

export const Navbar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brown[100],
  height: theme.scale.navbar,
  zIndex: 99,
  position: 'fixed',
  [theme.breakpoints.down('lg')]: {
    height: `calc(${theme.scale.navbar} - 20px)`,
  },
}));

export const LandingNavbar = styled(Navbar)(({ theme }) => ({
  backgroundColor: theme.palette.white['default'],
  borderColor: theme.palette.nav,
}));
