import axios from "../configs/axios";

export const createBooking = async ({
  date,
  timeStartAt,
  timeEndAt,
  languages,
  viewer,
  status,
}) => {
  try {
    const result = await axios.post("/booking/create", {
      date,
      timeStartAt,
      timeEndAt,
      languages,
      viewer,
      status,
    });
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateBooking = async ({
  bookingId,
  date,
  timeStartAt,
  timeEndAt,
  languages,
  viewer,
  status,
}) => {
  try {
    const result = await axios.patch(`/booking/update-booking/${bookingId}`, {
      date,
      timeStartAt,
      timeEndAt,
      languages,
      viewer,
      status,
    });
    return result.data;
  } catch (err) {
    throw err
  }
};

export const deleteBooking = async (bookingId) => {
  try {
    await axios.delete(`/booking/delete-booking/${bookingId}`);
  } catch (err) {
    throw err
  }
};

export const allowBookingAll = async () => {
  try {
    const result = await axios.get("/booking/allow-booking");
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const allBookingData = async () => {
  try {
    const result = await axios.get("/booking/getAll-booking");
    return result.data;
  } catch (err) {
    console.log(err);
  }
};
