import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import 'moment/locale/th';

import { Box, Container, List, ListItem, ListItemText, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../components/button/Primary';
import useStyles from '../../assets/styles';
import { Typo } from '../../assets/styles/typography';
import bookingConfig from '../../configs/booking';
import { routePath } from '../../configs/route';
import CustomModal from '../../components/modal/CustomModal';
import ModalSubmit from './ModalSubmit';
import { userBooking } from '../../services/userApi';
import { omisePayment } from '../../services/omise';
import { usedVoucher } from '../../services/voucherApi';

const CustomListItem = styled(ListItem)(() => ({
  display: 'flex',
  padding: '0',
  borderBottom: '1px solid rgba(20, 20, 20, 0.2)',
  '&:last-child': {
    border: 'none',
  },
  '.title': {
    flex: 3,
  },
  '.body': {
    flex: 5,
    fontWeight: 'bold',
  },
}));

export default function Checkout() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { ticketData } = location.state;
  const OmiseCard = useMemo(() => window.OmiseCard, []);

  useEffect(() => {
    OmiseCard.configure({
      publicKey: 'pkey_test_5nanfyudz112wzg9hwv',
      currency: 'thb',
      frameLabel: 'DOITUNG',
      submitLabel: 'pay now',
      buttonLabel: 'pay with Omise now',
    });
  }, [OmiseCard]);

  const onSubmit = async () => {
    if (ticketData.ticket === 'voucher') {
      const voucherNumber = ticketData.code;
      const result = await usedVoucher(voucherNumber);
      if (result.status === 200) {
        const bookingRes = await userBooking({
          ...ticketData,
          customerId: '-',
        });
        if (bookingRes) {
          navigate(routePath.payment.receipt, {
            state: { data: bookingRes },
          });
        }
      }
      return;
    }
    navigate(routePath.payment.index, { state: { data: ticketData } });
  };

  return (
    <>
      <Box
        className={`${classes.containerLayout} ${classes.mainLayout} ${classes.secondBackground} bg-brown-100 bg-blend-multiply min-h-screen flex flex-col pb-8`}
      >
        <Container maxWidth="sm" className="p-2">
          <Typo variant="h3" className="text-center py-8 drop-shadow-sm">
            {t('BOOKING.CHECKOUT.HEADER')}
          </Typo>
          <Typo variant="h4" className="pb-5 drop-shadow-sm">
            {t('BOOKING.CHECKOUT.DETAIL')}
          </Typo>
          <Paper className="px-8 py-2 mx-auto">
            <List>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.FULLNAME')}</Typo>
                </ListItemText>
                <ListItemText className="body">
                  {ticketData.name} {ticketData.lastName}
                </ListItemText>
              </CustomListItem>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.EMAIL')}</Typo>
                </ListItemText>
                <ListItemText className="body">{ticketData.email}</ListItemText>
              </CustomListItem>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.MOBILE')}</Typo>
                </ListItemText>
                <ListItemText className="body">{ticketData.mobile}</ListItemText>
              </CustomListItem>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.TICKET_TYPE')}</Typo>
                </ListItemText>
                <ListItemText className="body">
                  {t(`BOOKING.CHECKOUT.TICKET.${ticketData.ticket.toUpperCase()}`, {
                    ticketPrice:
                      bookingConfig.ticketPrice[ticketData.ticket.toUpperCase()].toLocaleString(
                        'en-US'
                      ),
                  })}
                </ListItemText>
              </CustomListItem>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.TICKET_AMOUNT')}</Typo>
                </ListItemText>
                <ListItemText className="body">
                  {t('BOOKING.CHECKOUT.SUMMARY.AMOUNT', {
                    ticketAmount: ticketData.numberVisitor,
                  })}
                </ListItemText>
              </CustomListItem>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.REMARK')}</Typo>
                </ListItemText>
                <ListItemText className="body">
                  {`${moment(ticketData.date).locale(i18n.language).format('LL')} — ${
                    ticketData.time
                  }`}
                </ListItemText>
              </CustomListItem>
              <CustomListItem>
                <ListItemText className="title">
                  <Typo variant="h6">{t('BOOKING.CHECKOUT.LIST_TITLE.TOTAL')}</Typo>
                </ListItemText>
                <ListItemText className="body">
                  {t('BOOKING.CHECKOUT.SUMMARY.TOTAL', {
                    total: ticketData.total.toLocaleString('en-US'),
                  })}
                </ListItemText>
              </CustomListItem>
            </List>
          </Paper>
          <Box className="my-5 drop-shadow">
            <Typo variant="h5">* {t('BOOKING.CHECKOUT.CONDITION.TITLE')}</Typo>
            <Typo variant="h6" className="indent-6">
              {t('BOOKING.CHECKOUT.CONDITION.BODY')}
            </Typo>
          </Box>
          <Box className="mt-6 text-center">
            <ButtonPrimary
              theme="dark"
              label={t('BUTTON.PREV')}
              classes="!mr-4"
              onClick={() => navigate(-1)}
            />
            <ButtonPrimary
              label={t('BUTTON.NEXT')}
              classes="!ml-4"
              onClick={() => setOpenModal(true)}
            />
          </Box>
        </Container>
      </Box>
      <CustomModal
        open={openModal}
        handleClose={(open) => setOpenModal(open)}
        maxWidth="528px"
        body={
          <ModalSubmit
            ticket={ticketData.ticket}
            setOpen={(open) => setOpenModal(open)}
            onSubmit={onSubmit}
          />
        }
      />
    </>
  );
}
