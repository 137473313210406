import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useStyles from "../assets/styles";

function NotFound() {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.secondBackground} bg-brown-100 bg-blend-multiply min-h-screen flex flex-col pb-8 h-full`}
    >
      <Grid
        container
        className="w-full h-full"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className="w-full h-full">
          <Typography
            className={`text-center pt-12`}
            style={{ fontSize: "10rem" }}
          >
            404
          </Typography>
          <Typography className={`text-center !text-6xl pb-12`}>
            Page not Found
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NotFound;
