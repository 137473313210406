import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, FormControlLabel, RadioGroup } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import InputTextField from '../../components/field/InputTextField';
import CustomRadio from '../../components/field/Radio';
import CustomCheckbox from '../../components/field/Checkbox';
import ButtonPrimary from '../../components/button/Primary';
import InputCount from '../../components/field/InputCount';
import DayPicker from '../../components/DatePicker/DayPicker';

// FORM
import { booking_form, personal_require, tax_require } from '../../constant/form.js';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import { allowBookingAll } from '../../services/bookingApi';
import { validateVoucher } from '../../services/voucherApi';
import bookingConfig from '../../configs/booking';
import { useCallback } from 'react';

const CustomBookingForm = styled(Box)(({ theme, locale }) => ({
  '.form .MuiTypography-root, .MuiFormControlLabel-root span.MuiTypography-root': {
    fontFamily: locale === 'th' ? theme.typography.font.th400 : theme.typography.fontFamily,
  },
}));

const DatePicker = styled(Box)(({ theme, locale }) => ({
  '.calender-picker': {
    margin: 'auto',
    boxShadow: 'none',
    borderRadius: '4px 4px 0 0',
  },
  '.rdp-caption_label': {
    fontSize: locale === 'th' ? '20px' : '16px',
  },
  '.rdp-head_cell span': {
    fontSize: locale === 'th' ? '18px' : '12px',
  },
  '.rdp-day': {
    fontSize: locale === 'th' ? '16px' : '14px',
  },
  '.rdp-day_today': {
    borderColor: 'transparent !important',
  },
  [theme.breakpoints.up('sm')]: {
    '.rdp-caption_label': {
      fontSize: locale === 'th' ? '24px' : '20px',
    },
    '.rdp-head_cell span': {
      fontSize: locale === 'th' ? '22px' : '16px',
    },
    '.rdp-day': {
      fontSize: locale === 'th' ? '22px' : '18px',
    },
    '.rdp-table': {
      maxWidth: 'calc(55px * 7)',
    },
    '.rdp-cell': {
      width: '50px',
      height: '50px',
      '.rdp-button': {
        width: '50px',
        height: '50px',
        maxWidth: 'unset',
      },
    },
  },
  [theme.breakpoints.only('xxl')]: {
    '.rdp-cell': {
      width: '55px',
      height: '55px',
      '.rdp-button': {
        width: '55px',
        height: '55px',
      },
    },
  },
  [theme.breakpoints.down('375')]: {
    '.round-wrap': {
      maxWidth: '222px',
    },
    '.MuiGrid-root.MuiGrid-item': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
}));

export default function BookingForm() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [form, setForm] = useState(booking_form);
  const [disabled, setDisabled] = useState(true);
  const [dateAllow, setDateAllow] = useState([]);
  const [timeAllow, setTimeAllow] = useState({});
  const [selectedSlot, setSelectedSlot] = useState({});
  const [voucherErrCode, setVoucherErrCode] = useState('');

  const onSubmit = async () => {
    if (form.ticket === 'voucher') {
      const voucherNumber = form.code;
      const result = await validateVoucher(voucherNumber);

      if (result.status !== 200) {
        setVoucherErrCode(result.data.code);
        return;
      }
    }

    navigate(routePath.booking.checkout, { state: { ticketData: form } });
  };

  const handleField = useCallback(() => {
    let require = page === 0 ? personal_require : tax_require;
    if (form.ticket === 'voucher') {
      require = [...require, 'code'];
    }
    if (_.every(require, (req, index) => form[req])) setDisabled(false);
    else setDisabled(true);
  }, [form, page]);

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleChangeTicketType = (type) => {
    if (voucherErrCode) setVoucherErrCode('');
    setForm({
      ...form,
      ticket: type,
      numberVisitor:
        type === 'normal' ? 1 : type === 'private' ? bookingConfig.private.requiredTickets : 1,
      total:
        type === 'normal'
          ? bookingConfig.ticketPrice.NORMAL
          : type === 'private'
          ? bookingConfig.ticketPrice.PRIVATE
          : bookingConfig.ticketPrice.VOUCHER,
      code: type !== 'voucher' ? '' : form.code,
    });
  };

  const fetchBooking = async () => {
    const bookingList = await allowBookingAll();

    const dateTimeList = {};
    bookingList.result.forEach((booking) => {
      const newDate = new Date(booking.date);
      newDate.setHours(0, 0, 0, 0);
      if (!dateTimeList.hasOwnProperty(newDate)) {
        dateTimeList[newDate] = [];
      }
      dateTimeList[newDate].push(booking);
    });
    setDateAllow(Object.keys(dateTimeList).map((date) => new Date(date)));
    setTimeAllow(dateTimeList);
  };

  useEffect(() => {
    fetchBooking();
  }, []);

  useEffect(() => {
    handleField();
  }, [handleField]);

  return (
    <CustomBookingForm
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.secondBackground} bg-brown-100 bg-blend-multiply min-h-screen flex flex-col pb-8`}
      locale={i18n.language}
    >
      <Typo
        variant="h2"
        className={`!font-medium text-center pt-8 pb-5 !text-2xl sm:!text-4xl xxl:!text-[40px]`}
      >
        {t('BOOKING.FORM.TITLE')}
      </Typo>
      <Box className="form mx-auto mt-8 max-w-xl w-full md:max-w-3xl lg:max-w-[1152px] xxl:max-w-6xl">
        <Grid spacing={2} container className="w-full h-full" justifyContent="center">
          {page === 0 && (
            <Grid item lg={5} className={`w-full pt-4`}>
              <DatePicker locale={i18n.language}>
                <Box className=" rounded-md bg-dark-800 mx-auto pt-2 pb-4 px-4 w-full max-w-fit">
                  <Typo
                    variant="h3"
                    className={`text-center !text-white ${
                      i18n.language === 'en' ? '!text-xl sm:!text-[28px]' : 'sm:!text[10px]'
                    }`}
                  >
                    {t('BOOKING.FORM.BODY.ROUND')}
                  </Typo>
                  <DayPicker
                    isFetch={true}
                    locale={i18n.language}
                    theme="dark"
                    date={form.date}
                    setDate={(value) => {
                      if (value) {
                        handleChange('date', value);
                      }
                      if (!_.isEmpty(selectedSlot)) {
                        setForm((prev) => ({ ...prev, time: '' }));
                        setSelectedSlot({});
                      }
                    }}
                    allowDays={dateAllow}
                  />
                  <Box className="mt-4">
                    <Grid spacing={2} container className="w-full h-full" justifyContent="center">
                      {timeAllow[form.date]?.map((slot, index) => {
                        const formattedTime = `${slot.timeStartAt.slice(
                          0,
                          -3
                        )} - ${slot.timeEndAt.slice(0, -3)}`;
                        return (
                          <Grid key={index} item xs={6} className={`w-full pt-4 text-white`}>
                            <Box
                              className={`border-solid border-2 border-white rounded text-white text-center cursor-pointer ${
                                i18n.language === 'en'
                                  ? 'text-base sm:text-lg'
                                  : 'text-xl sm:text-2xl'
                              } ${selectedSlot.bookingId === slot.bookingId ? 'bg-brown-700' : ''}`}
                              onClick={() => {
                                handleChange('time', formattedTime);
                                setSelectedSlot(slot);
                              }}
                            >
                              {formattedTime}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
                {!_.isEmpty(selectedSlot) && (
                  <Typo variant="h5" className="text-center !font-bold !mt-8">
                    รอบที่คุณเลือกสามารถจองได้อีก {selectedSlot.viewer - selectedSlot.numOfBooked}{' '}
                    ตั๋ว
                  </Typo>
                )}
              </DatePicker>
            </Grid>
          )}
          <Grid item lg={7} className={`w-full`}>
            <Typo
              variant="h2"
              className={`!font-medium text-center pb-4 !text-2xl sm:!text-[32px] xxl:!text-[36px]`}
            >
              {t(`BOOKING.FORM.BODY.TITLE.PAGE_${page}`)}
            </Typo>

            {(page === 0 && (
              <>
                <Box>
                  <Grid
                    spacing={2}
                    container
                    className="w-full h-full"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item sm={6} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.NAME')}</Typo>
                      <InputTextField
                        name="name"
                        value={form.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={6} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.SURNAME')}</Typo>
                      <InputTextField
                        name="lastName"
                        value={form.lastName}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.MOBILE')}</Typo>
                      <InputTextField
                        name="mobile"
                        value={form.mobile}
                        onChange={(e) => handleChange('mobile', e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.EMAIL')}</Typo>
                      <InputTextField
                        name="email"
                        value={form.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.AGENCY')}</Typo>
                      <InputTextField
                        name="companyName"
                        value={form.companyName}
                        onChange={(e) => handleChange('companyName', e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} className={`w-full`}>
                      <RadioGroup aria-labelledby="ticket-type" name="ticket-type">
                        <FormControlLabel
                          value="normal"
                          className="sm:!mr-6"
                          control={
                            <CustomRadio
                              value="normal"
                              checked={form.ticket === 'normal'}
                              onChange={(e) => handleChangeTicketType('normal')}
                              disabled={!form.time}
                            />
                          }
                          label={t('FORM.NORMAL_TICKET')}
                          classes={{ label: '!ml-2 !text-lg sm:!text-[22px]' }}
                        />
                        {_.isEmpty(selectedSlot) ||
                        selectedSlot.viewer - selectedSlot.numOfBooked ===
                          bookingConfig.private.requiredTickets ? (
                          <FormControlLabel
                            value="private"
                            control={
                              <CustomRadio
                                value="private"
                                checked={form.ticket === 'private'}
                                onChange={(e) => handleChangeTicketType('private')}
                                disabled={!form.time}
                              />
                            }
                            label={t('FORM.PRIVATE_TICKET')}
                            classes={{ label: '!ml-2 !text-lg sm:!text-[22px]' }}
                          />
                        ) : (
                          ''
                        )}
                        <Box className="mt-1">
                          <FormControlLabel
                            value="voucher"
                            control={
                              <CustomRadio
                                value="voucher"
                                checked={form.ticket === 'voucher'}
                                onChange={(e) => handleChangeTicketType('voucher')}
                                disabled={!form.time}
                              />
                            }
                            label={t('FORM.VOUCHER_TICKET')}
                            classes={{
                              label: '!ml-2 !text-lg sm:!text-[22px]',
                            }}
                          />
                          {form.ticket === 'voucher' && (
                            <InputTextField
                              name="code"
                              onChange={(e) => handleChange('code', e.target.value)}
                              style={{ maxWidth: 120 }}
                              value={form.code}
                            />
                          )}
                          <span className="mx-4 text-red-500">
                            {voucherErrCode && t(`BOOKING.VOUCHER_ERR.${voucherErrCode}`)}
                          </span>
                        </Box>
                      </RadioGroup>
                    </Grid>
                    <Grid item lg={12} className={`w-full`}>
                      <Box className="flex justify-between">
                        <Box>
                          <Typo variant="h5">{t('FORM.NUMBER_OF_VISITORS')}</Typo>
                          <InputCount
                            value={parseInt(form.numberVisitor)}
                            min={1}
                            max={selectedSlot.viewer - selectedSlot.numOfBooked}
                            onChange={(e) => {
                              const min = 1;
                              const max = selectedSlot.viewer - selectedSlot.numOfBooked;
                              const value = e.target.value;
                              if (value <= min)
                                setForm({
                                  ...form,
                                  numberVisitor: min,
                                  total: bookingConfig.ticketPrice.NORMAL * min,
                                });
                              else if (value >= max)
                                setForm({
                                  ...form,
                                  numberVisitor: max,
                                  total: bookingConfig.ticketPrice.NORMAL * max,
                                });
                              else
                                setForm({
                                  ...form,
                                  numberVisitor: value,
                                  total: bookingConfig.ticketPrice.NORMAL * value,
                                });
                            }}
                            setValue={(value) =>
                              setForm({
                                ...form,
                                numberVisitor: value,
                                total: bookingConfig.ticketPrice.NORMAL * value,
                              })
                            }
                            disabled={form.ticket !== 'normal'}
                          />
                        </Box>
                        <Box>
                          <Typo variant="h5">{t('FORM.TOTAL')}</Typo>
                          <InputTextField
                            name="total"
                            className="pointer-events-none"
                            style={{ maxWidth: 120 }}
                            value={form.total}
                            disabled={form.ticket === 'voucher'}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={12} className={`w-full`}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            name="tax"
                            checked={form.tax}
                            disabled={form.ticket === 'voucher'}
                            onChange={(e) => {
                              handleChange('tax', !form.tax);
                            }}
                          />
                        }
                        label={t('FORM.TAX')}
                        className="!m-0"
                        classes={{ label: '!mr-2 !text-lg sm:!text-[22px]' }}
                        labelPlacement="start"
                        // disabled={form.ticket === "voucher"}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className="flex flex-col md:flex-row justify-between w-full items-center md:gap-12 py-5">
                  <Typo className="!text-xl !mx-auto" sx={{ lineHeight: 'normal' }}>
                    {t('BOOKING.FORM.BODY.FOOTER.LINE_1')}
                    <br />
                    {t('BOOKING.FORM.BODY.FOOTER.LINE_2')}
                  </Typo>
                  <Box className="text-center mt-10 md:mt-0">
                    <ButtonPrimary
                      disabled={disabled}
                      label={t('BUTTON.NEXT')}
                      onClick={() => {
                        if (form.tax) setPage(1);
                        else onSubmit();
                      }}
                    />
                  </Box>
                </Box>
              </>
            )) || (
              <>
                <Box>
                  <Grid
                    spacing={2}
                    container
                    className="w-full h-full"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item sm={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.COMPANY_NAME')}</Typo>
                      <InputTextField
                        name="companyNameTax"
                        value={form.companyNameTax}
                        onChange={(e) => handleChange('companyNameTax', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.TAX_IDENTIFICATION_NUMBER')}</Typo>
                      <InputTextField
                        name="taxIdentification"
                        value={form.taxIdentification}
                        onChange={(e) => handleChange('taxIdentification', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.COMPANY_REGISTRATION_ADDRESS')}</Typo>
                      <InputTextField
                        name="companyAddressTax"
                        value={form.companyAddressTax}
                        onChange={(e) => handleChange('companyAddressTax', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={6} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.COMPANY_REGISTRATION_ADDRESS_DISTRICT')}</Typo>
                      <InputTextField
                        name="companyAddressDistrict"
                        value={form.companyAddressDistrict}
                        onChange={(e) => handleChange('companyAddressDistrict', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={6} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.COMPANY_REGISTRATION_ADDRESS_CITY')}</Typo>
                      <InputTextField
                        name="companyAddressCity"
                        value={form.companyAddressCity}
                        onChange={(e) => handleChange('companyAddressCity', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={6} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.COMPANY_REGISTRATION_ADDRESS_COUNTY')}</Typo>
                      <InputTextField
                        name="companyAddressCounty"
                        value={form.companyAddressCounty}
                        onChange={(e) => handleChange('companyAddressCounty', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={6} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.COMPANY_REGISTRATION_ADDRESS_POSTCODE')}</Typo>
                      <InputTextField
                        name="companyAddressPostCode"
                        value={form.companyAddressPostCode}
                        onChange={(e) => handleChange('companyAddressPostCode', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={12} className={`w-full`}>
                      <Typo variant="h5">{t('FORM.CONTACT_NUMBER')}</Typo>
                      <InputTextField
                        name="companyNumberTax"
                        value={form.companyNumberTax}
                        onChange={(e) => handleChange('companyNumberTax', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className="mt-10 text-center">
                  <ButtonPrimary
                    theme="dark"
                    label={t('BUTTON.PREV')}
                    onClick={() => setPage(0)}
                    classes="!mr-4"
                  />
                  <ButtonPrimary
                    disabled={disabled}
                    label={t('BUTTON.NEXT')}
                    onClick={onSubmit}
                    classes="!ml-4"
                  />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </CustomBookingForm>
  );
}
