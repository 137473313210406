export const timePattern = (time) => {
  const oldPattern = new Date(time);
  let hours = oldPattern.getHours();
  let minutes = oldPattern.getMinutes();
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  return `${hours}:${minutes}`;
};

export function datePattern(targetDate) {
  const newDate = new Date(targetDate);
  let year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let date = newDate.getDate();
  if (date < 10) date = '0' + date;
  if (month < 10) month = '0' + month;
  return `${year}-${month}-${date}`;
}

export function setCookie(cname, cvalue, time) {
  const currentTime = new Date();

  const targetTime = new Date(
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
    time.slice(0, 2),
    time.slice(3, 5),
    0
  );
  const cookieExpire = new Date();
  cookieExpire.setTime(targetTime.getTime());

  const thirtyMinutesInMillis = 30 * 60 * 1000;
  cookieExpire.setTime(cookieExpire.getTime() + thirtyMinutesInMillis);

  let expires = 'expires=' + cookieExpire.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
