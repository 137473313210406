import { useState, useRef } from 'react';
import { Box, Button, Fade, Slide } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../assets/styles/typography';
import PrimaryHeader from '../components/header/PrimaryHeader';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../assets/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// IMAGE
import Image_00 from '../assets/images/history/image0.png';
import Image_01 from '../assets/images/history/image1.png';
import Image_02 from '../assets/images/history/image2.png';
import Image_03 from '../assets/images/history/image3.png';
import Image_04 from '../assets/images/history/image4.png';
import Image_05 from '../assets/images/history/image5.png';
import Image_06 from '../assets/images/history/image6.png';
import Image_07 from '../assets/images/history/image7.png';

export default function History() {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const [items, setItems] = useState([
    {
      title: '',
      content: 'HISTORY.ROOM_0.CONTENT',
      image: Image_00,
    },
    {
      title: 'HISTORY.ROOM_1.TITLE',
      content: 'HISTORY.ROOM_1.CONTENT',
      image: Image_01,
    },

    {
      title: 'HISTORY.ROOM_2.TITLE',
      content: 'HISTORY.ROOM_2.CONTENT',
      image: Image_02,
    },
    {
      title: 'HISTORY.ROOM_3.TITLE',
      content: 'HISTORY.ROOM_3.CONTENT',
      image: Image_03,
    },
    {
      title: 'HISTORY.ROOM_4.TITLE',
      content: 'HISTORY.ROOM_4.CONTENT',
      image: Image_04,
    },
    {
      title: 'HISTORY.ROOM_5.TITLE',
      content: 'HISTORY.ROOM_5.CONTENT',
      image: Image_05,
    },
    {
      title: 'HISTORY.ROOM_6.TITLE',
      content: 'HISTORY.ROOM_6.CONTENT',
      image: Image_06,
    },
    {
      title: 'HISTORY.ROOM_7.TITLE',
      content: 'HISTORY.ROOM_7.CONTENT',
      image: Image_07,
    },
  ]);
  const [show, setShow] = useState(0);
  const [direction, setDirection] = useState('left');
  const containerRef = useRef(null);

  const handlePrev = () => {
    setDirection('right');
    if (show === 0) setShow(7);
    else setShow(show - 1);
  };

  const handleNext = () => {
    setDirection('left');
    if (show > 6) setShow(0);
    else setShow(show + 1);
  };

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.mainBackground} min-h-screen flex flex-col pb-10 overflow-hidden`}
    >
      <Box className={`${classes.container} mt-6 mb-4`}>
        <PrimaryHeader title={t('HOME.SECTION_HISTORY.TITLE')} />
      </Box>

      <Box className="max-w-[990px] xxl:max-w-5xl mx-auto">
        {_.map(items, (item, index) => {
          return (
            <Box key={index} className={`${show === index ? 'block' : 'hidden'}`}>
              {item.title && (
                <Typo
                  variant="h3"
                  className={`!font-medium ${i18n.language !== 'th' ? '!mb-1' : ''}`}
                >
                  {t(item.title)}
                </Typo>
              )}
              <Typo
                variant="h4"
                className={`${index === 0 ? 'indent-12' : ''} ${
                  i18n.language === 'th' ? '!font-th400' : ''
                } text-justify`}
              >
                {t(item.content)}
              </Typo>
              <Box className={`relative flex items-center mt-8`}>
                <Button
                  onClick={handlePrev}
                  className={
                    '!absolute left-0 w-1/3 !justify-start !bg-transparent h-full !hidden md:!flex'
                  }
                >
                  <ArrowBackIosNewIcon className="!text-5xl" />
                </Button>
                <Box className="flex-1 overflow-hidden" ref={containerRef}>
                  <Slide direction={direction} in={show === index} timeout={1000}>
                    <img src={item.image} alt={`room-${index}`} className={'mx-auto'} />
                  </Slide>
                </Box>
                <Button
                  onClick={handleNext}
                  className={
                    '!absolute right-0 w-1/3 !justify-end !bg-transparent h-full !hidden md:!flex'
                  }
                >
                  <ArrowForwardIosIcon className="!text-5xl" />
                </Button>
              </Box>
              <Box className="flex mt-4 md:hidden">
                <Button onClick={handlePrev} className={'!bg-transparent w-full'}>
                  <ArrowBackIosNewIcon className="!text-xl" />
                  <Typo variant="h4" className="pl-3">
                    ย้อนกลับ
                  </Typo>
                </Button>
                <Button onClick={handleNext} className={'!bg-transparent w-full'}>
                  <Typo variant="h4" className="pr-3">
                    ถัดไป
                  </Typo>
                  <ArrowForwardIosIcon className="!text-xl" />
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
