import Main from '../layouts/Main';

// ROUTE
import { routePath } from './route';

// USER
import LandingPage from '../pages/Landing';
import History from '../pages/History';
import BookingIndex from '../pages/Booking/Index';
import BookingForm from '../pages/Booking/BookingForm';
import BookingCheckout from '../pages/Booking/Checkout';
import PaymentIndex from '../pages/Payment/Index';
import PaymentForm from '../pages/Payment/PaymentForm';
import PaymentReceipt from '../pages/Payment/PaymentReceipt';

// ADMIN
import Admin from '../pages/Admin/Index';
import AdminLogin from '../pages/Admin/Login';

import Policy from '../pages/Policy';
import NotFound from '../pages/NotFound';

const components = {
  landing: {
    url: routePath.home,
    page: LandingPage,
    layout: Main,
  },
  history: {
    url: routePath.history,
    page: History,
    layout: Main,
  },
  booking: {
    url: routePath.booking.index,
    page: BookingIndex,
    layout: Main,
  },
  bookingForm: {
    url: routePath.booking.form,
    page: BookingForm,
    layout: Main,
  },
  bookingCheckout: {
    url: routePath.booking.checkout,
    page: BookingCheckout,
    layout: Main,
  },
  payment: {
    url: routePath.payment.index,
    page: PaymentIndex,
    layout: Main,
  },
  paymentForm: {
    url: routePath.payment.form,
    page: PaymentForm,
    layout: Main,
  },
  paymentReceipt: {
    url: routePath.payment.receipt,
    page: PaymentReceipt,
    layout: Main,
  },
  admin: {
    url: routePath.admin,
    page: Admin,
    layout: Main,
  },
  adminLogin: {
    url: routePath.adminLogin,
    page: AdminLogin,
    layout: Main,
  },
  notFound: {
    url: '*',
    page: NotFound,
    layout: Main,
  },
  policy: {
    url: routePath.policy,
    page: Policy,
    layout: Main,
  },
};

const {
  landing,
  notFound,
  admin,
  adminLogin,
  history,
  booking,
  bookingForm,
  bookingCheckout,
  payment,
  paymentForm,
  paymentReceipt,
  policy,
} = components;

const router = {
  guest: [
    landing,
    notFound,
    adminLogin,
    history,
    booking,
    bookingForm,
    bookingCheckout,
    payment,
    paymentForm,
    paymentReceipt,
    policy,
  ],
  admin: [
    landing,
    notFound,
    admin,
    history,
    booking,
    bookingForm,
    bookingCheckout,
    payment,
    paymentForm,
    paymentReceipt,
    policy,
  ],
};

export default router;

// let defaultUrl = "/";
// for (const key in components) {
//   const pageObj = components[key];
//   if (pageObj.hasOwnProperty("isFirstPage") && pageObj["isFirstPage"]) {
//     defaultUrl = pageObj["url"];
//     break;
//   }
// }
// export const firstPageUrl = defaultUrl;
