import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import moment from 'moment';

// COMPONENT
import InputDate from '../../../components/field/InputDate';

// ICONS
import Edit from '../../../assets/icons/Edit';

// STYLE
import { styled } from '@mui/system';
import * as UseStyled from '../UseStyled';
import useStyles from '../../../assets/styles/index';
import { Typo } from '../../../assets/styles/typography';
import { getUserBookingById, updateBooking } from '../../../services/userApi';
import { allowBookingAll } from '../../../services/bookingApi';
import ButtonPrimary from '../../../components/button/Primary';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const Title = styled(Typo)(({ theme, font }) => ({
  // textShadow: `0px -1px ${theme.palette.brown[700]}`,
}));

export default function BookingDetail(props) {
  const { bookingId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [dataBooking, setDataBooking] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openSelectTime = Boolean(anchorEl);
  const [date, setDate] = useState('');
  const [dateList, setDateList] = useState([]);
  const [times, setTimes] = useState({});
  const [time, setTime] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const submitUpdate = async () => {
    try {
      await updateBooking({
        id: userData.id,
        date,
        time,
      });
      setIsSuccess(true);
      await fetchDataUser();
    } catch (err) {
      setErrorCode(err.response.data.message);
    }
  };

  const isDisableButton = () => {
    return time === '-' || userData['Booking.date'] === date;
  };

  const fetchDataUser = async () => {
    const dateResult = await getUserBookingById(bookingId);
    setUserData(dateResult.result);
    setTime(
      `${dateResult.result['Booking.timeStartAt']?.slice(0, -3)} - ${dateResult.result[
        'Booking.timeEndAt'
      ]?.slice(0, -3)}`
    );
    setDate(dateResult.result['Booking.date']);
    setDataBooking(dataBooking);
  };

  const calculateDateAllow = async () => {
    const dataBooking = await allowBookingAll();
    const timeAllow = {};
    dataBooking.result.forEach((data) => {
      const key = format(new Date(data.date), 'yyyy-MM-dd');
      const value = `${data.timeStartAt.slice(0, -3)} - ${data.timeEndAt.slice(0, -3)}`;
      if (timeAllow.hasOwnProperty(key)) {
        Object.assign(timeAllow, { [key]: [...timeAllow[key], value] });
      } else {
        Object.assign(timeAllow, { [key]: [value] });
      }
    });
    const dateAllow = dataBooking.result.map((data) => new Date(data.date));
    setTimes(timeAllow);
    setDateList(dateAllow);
  };

  useEffect(() => {
    fetchDataUser();
  }, []);

  useEffect(() => {
    calculateDateAllow();
  }, [date]);

  const handleOpenSelectTime = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelectTime = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={'admin p-4 sm:p-6 lg:px-10 mb-12'}>
      <Box>
        <Title font="en" variant="h4" className={'text-brown-700 !font-th'}>
          Information
        </Title>
        {/* name */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Name<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.name}
          </Typo>
        </Box>
        {/* Surname */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Surname<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.surname}
          </Typo>
        </Box>
        {/* Booking number */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Booking number<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.bookingId}
          </Typo>
        </Box>
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Ticket number<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.ticketCode}
          </Typo>
        </Box>
        {/* Tel */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Tel<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.tel}
          </Typo>
        </Box>
        {/* E-mail */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            E-mail<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.email}
          </Typo>
        </Box>
        {/* Quantity */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Quantity<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.viewer}
          </Typo>
        </Box>
        {/* Date */}
        <UseStyled.InputDate className="relative sm:static flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Date<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {moment(date).format('DD MMM YYYY')}
          </Typo>
          <Box
            className={'!ml-3 sm:relative'}
            sx={{
              '&:hover > button': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <InputDate
              isFetch={true}
              type="edit"
              value={date}
              setValue={(value) => {
                setErrorCode('');
                setIsSuccess(false);
                setDate(value);
                setTime('-');
              }}
              locale="en"
              disabledClear
              dateAllow={dateList}
            />
          </Box>
        </UseStyled.InputDate>
        {/* Time */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Time<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {time}
          </Typo>
          <Box
            sx={{
              '&:hover > button': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <IconButton
              aria-label="time"
              className={'!ml-3'}
              id="basic-button"
              aria-controls={openSelectTime ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openSelectTime ? 'true' : undefined}
              onClick={handleOpenSelectTime}
              disableRipple
            >
              <Edit className={'w-4 h-4 fill-brown-900'} />
            </IconButton>

            <Menu
              id="basic-menu"
              value
              anchorEl={anchorEl}
              open={openSelectTime}
              onClose={handleCloseSelectTime}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              className={`${classes.basicSelectStyle}`}
              sx={{
                '.MuiPaper-root': {
                  marginLeft: '-22px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {_.map(
                date ? times[format(new Date(date), 'yyyy-MM-dd')] : ['No Time'],
                (i, index) => {
                  return date ? (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setErrorCode('');
                        setIsSuccess(false);
                        setTime(i);
                        handleCloseSelectTime();
                      }}
                      disabled={i === time}
                    >
                      {i}
                    </MenuItem>
                  ) : (
                    <MenuItem key={index}>{i}</MenuItem>
                  );
                }
              )}
            </Menu>
          </Box>
        </Box>
        {/* Tour guide language */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Tour guide language<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData['Booking.languages']}
          </Typo>
        </Box>
        {/* Payment ID */}
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Payment ID<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.paymentId}
          </Typo>
        </Box>
        <Box className="flex items-center">
          <Typo font="en" variant="h5" className={'!font-th'}>
            Ticket type<span className={'px-1'}>:</span>
          </Typo>
          <Typo font="en" variant="h5" className={'!font-th400'}>
            {userData.ticketType}
          </Typo>
        </Box>
      </Box>

      {Number(userData.isTax) !== 0 && (
        <Box className={'pt-5'}>
          <Title font="en" variant="h4" className={'text-brown-700 !font-th'}>
            Company
          </Title>
          <Box className="flex items-center">
            <Typo font="en" variant="h5" className={'!font-th'}>
              Company name<span className={'px-1'}>:</span>
            </Typo>
            <Typo font="en" variant="h5" className={'!font-th400'}>
              {userData['Tax.nameCompany']}
            </Typo>
          </Box>
          <Box className="flex items-center">
            <Typo font="en" variant="h5" className={'!font-th'}>
              Taxpayer number<span className={'px-1'}>:</span>
            </Typo>
            <Typo font="en" variant="h5" className={'!font-th400'}>
              {userData['Tax.taxpayerIdentification']}
            </Typo>
          </Box>

          <Box className="flex items-center">
            <Typo font="en" variant="h5" className={'!font-th'}>
              Address<span className={'px-1'}>:</span>
            </Typo>
            <Typo font="en" variant="h5" className={'!font-th400'}>
              {userData['Tax.address']}
            </Typo>
          </Box>
          <Box className="flex items-center">
            <Typo font="en" variant="h5" className={'!font-th'}>
              Tel<span className={'px-1'}>:</span>
            </Typo>
            <Typo font="en" variant="h5" className={'!font-th400'}>
              {userData['Tax.phone']}
            </Typo>
          </Box>
          <Box className="flex items-center">
            <Typo font="en" variant="h5" className={'!font-th'}>
              E-mail contact company<span className={'px-1'}>:</span>
            </Typo>
            <Typo font="en" variant="h5" className={'!font-th400'}>
              {userData['Tax.email']}
            </Typo>
          </Box>
        </Box>
      )}
      <Typo variant="h6" className="text-center text-red-500 !mt-8">
        {errorCode ? t(`BOOKING_DETAIL.${errorCode}`) : ''}
      </Typo>
      {isSuccess && (
        <Typo variant="h6" className="text-center text-green-500 !mt-8">
          {t(`BOOKING.SUCCESS`)}
        </Typo>
      )}
      <Box className={'text-right mt-8'}>
        <ButtonPrimary
          disabled={isDisableButton()}
          theme="dark"
          label="Save"
          font="th"
          classes={'!text-lg lg:!text-2xl sm:w-32 lg:w-44'}
          onClick={submitUpdate}
        />
      </Box>
    </Box>
  );
}
