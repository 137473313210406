import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
} from '@mui/material';

// STYLE
import { styled } from '@mui/system';

const StyleTable = styled(Table)(({ theme }) => ({
  '.MuiTableHead-root': {
    '.MuiTableRow-root': {
      '.MuiTableCell-root': {
        padding: '1.5rem 0 0.5rem',
        fontSize: 26,
        border: 0,
        color: theme.palette.brown[900],
        // textShadow: `0px -1px ${theme.palette.brown[900]}`,
      },
    },
  },
  '.MuiTableBody-root': {
    '.MuiTableRow-root': {
      '.MuiTableCell-root': {
        padding: '4px 0.5rem',
        fontSize: 20,
        borderBottom: `2px solid ${theme.palette.brown[700]}`,
        color: theme.palette.brown[900],
      },
      '&:last-of-type': {
        '.MuiTableCell-root': {
          border: 0,
        },
      },
      '&.has-tax': {
        '.MuiTableCell-root': {
          color: '#5979e2',
        },
      },
      '&.has-red': {
        '.MuiTableCell-root': {
          color: '#FB1F1F',
        },
      },
      '&.has-green': {
        '.MuiTableCell-root': {
          color: '#2FA512',
        },
      },
    },
  },
}));

const StyleTablePagination = styled(Pagination)(({ theme }) => ({
  '.MuiPagination-ul': {
    justifyContent: 'flex-end',
  },
  '.MuiPaginationItem-root': {
    fontSize: '16px',
    width: '30px',
    height: '30px',
    minWidth: 'unset',
    '&:hover, &.Mui-selected': {
      backgroundColor: theme.palette.brown[100],
    },
  },
}));

export default function CustomTable(props) {
  const { tableMinWidth, tableHead, tableBody, page, dataCount, setPage } = props;

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <TableContainer>
        <StyleTable sx={{ minWidth: tableMinWidth || '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {_.map(tableHead, (head, index) => {
                return (
                  <TableCell
                    key={index}
                    align={head.align}
                    className={'!font-th'}
                    sx={{ width: head.width }}
                  >
                    {head.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBody}
            {!tableBody.length && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  align="center"
                  className="text-center !font-th !py-6"
                  colSpan={tableHead.length}
                >
                  ไม่พบข้อมูล
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyleTable>
      </TableContainer>
      <StyleTablePagination
        count={Math.ceil(dataCount / 20)}
        page={page}
        onChange={handleChangePage}
        className="pt-4 pb-3"
      />
    </>
  );
}
