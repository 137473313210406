const ACCESS_TOKEN = "ACCESS_TOKEN";

export const setToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const checkToken = () => {
  const token = getToken();

  if (token) {
    return "admin";
  }
  return "guest";
};