import axios from "../configs/axios";

export const cartCheckoutPage = async (email, name, lastname, total, token) => {
  try {
    const result = await axios.post("/omise/checkout-credit-card", {
      email,
      name,
      lastname,
      total,
      token,
    });
    return result.data;
  } catch (err) {
    console.log(err);
  }
};
