import { Box, Container, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from '../assets/styles';
import { Typo } from '../assets/styles/typography';

const contact = {
  website: 'https://hallofinspiration.maefahluang.org/',
  email: 'mflfvirtualtour@doitung.org',
};

function I18nContactInterpol({ i18nKey, contactType }) {
  const contactTo =
    contactType === 'email'
      ? `mailto:${contact[contactType]}`
      : contact[contactType];

  return (
    <Trans
      i18nKey={i18nKey}
      values={{
        [contactType]: contact[contactType],
      }}
    >
      <Link className="px-1" target="_blank" href={contactTo} />
    </Trans>
  );
}

export default function Policy() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} bg-[#F5EDE4] min-h-screen flex flex-col pb-10 overflow-hidden`}
    >
      <Container>
        {/* POLICY SECTION */}
        <Box className="p-6 flex flex-col gap-2">
          <Typo
            variant={i18n.language === 'th' ? 'h1' : 'h2'}
            className="text-center !font-medium"
            gutterBottom
          >
            {t('POLICY.HEADER')}
          </Typo>

          {/* SECTION 1 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('POLICY.SECTION_1.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('POLICY.SECTION_1.BODY')}
          </Typo>

          {/* SECTION 2 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('POLICY.SECTION_2.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('POLICY.SECTION_2.BODY_1')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('POLICY.SECTION_2.BODY_2')}
          </Typo>

          {/* SECTION 3 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('POLICY.SECTION_3.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('POLICY.SECTION_3.BODY')}
          </Typo>

          {/* SECTION 4 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('POLICY.SECTION_4.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('POLICY.SECTION_4.BODY')}
          </Typo>

          {/* SECTION 5 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('POLICY.SECTION_5.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('POLICY.SECTION_5.BODY')}
          </Typo>

          {/* SECTION 6 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('POLICY.SECTION_6.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('POLICY.SECTION_6.BODY_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('POLICY.SECTION_6.BODY_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              <I18nContactInterpol
                i18nKey="POLICY.SECTION_6.BODY_LI_3"
                contactType="website"
              />
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              <Typo
                variant={i18n.language === 'th' ? 'h6' : 'body1'}
                component="ol"
                className="pl-8 list-decimal"
                gutterBottom
              >
                <Typo
                  variant={i18n.language === 'th' ? 'h6' : 'body1'}
                  component="li"
                  gutterBottom
                >
                  {t('POLICY.SECTION_6.BODY_LI_3_1')}
                </Typo>
                <Typo
                  variant={i18n.language === 'th' ? 'h6' : 'body1'}
                  component="li"
                  gutterBottom
                >
                  <I18nContactInterpol
                    i18nKey="POLICY.SECTION_6.BODY_LI_3_2"
                    contactType="email"
                  />
                </Typo>
              </Typo>
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('POLICY.SECTION_6.BODY_LI_4')}
            </Typo>
          </Typo>
        </Box>

        {/* PRIVACY SECTION */}
        <Box className="p-6">
          <Typo
            variant={i18n.language === 'th' ? 'h1' : 'h2'}
            className="text-center !font-medium"
            gutterBottom
          >
            {t('PRIVACY.HEADER')}
          </Typo>

          {/* SECTION 1 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_1.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            <I18nContactInterpol
              i18nKey="PRIVACY.SECTION_1.BODY_1"
              contactType="website"
            />
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_1.BODY_2')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_1.BODY_3')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_1.BODY_4')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_1.BODY_5')}
          </Typo>

          {/* SECTION 2 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_2.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_2.BODY_1')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_3')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_4')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_5')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_6')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_1_LI_7')}
            </Typo>
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_2.BODY_2')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_2_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_2_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_2.BODY_2_LI_3')}
            </Typo>
            {i18n.language === 'en' && (
              <>
                <Typo
                  variant={i18n.language === 'th' ? 'h6' : 'body1'}
                  component="li"
                  gutterBottom
                >
                  {t('PRIVACY.SECTION_2.BODY_2_LI_4')}
                </Typo>
                <Typo
                  variant={i18n.language === 'th' ? 'h6' : 'body1'}
                  component="li"
                  gutterBottom
                >
                  {t('PRIVACY.SECTION_2.BODY_2_LI_5')}
                </Typo>
              </>
            )}
          </Typo>

          {/* SECTION 3 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_3.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_3.BODY_1')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_3')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_4')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_5')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_6')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_7')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_8')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_3.BODY_1_LI_9')}
            </Typo>
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_3.BODY_2')}
          </Typo>

          {/* SECTION 4 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_4.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_4.BODY')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_4.BODY_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_4.BODY_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_4.BODY_LI_3')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_4.BODY_LI_4')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_4.BODY_LI_5')}
            </Typo>
          </Typo>

          {/* SECTION 5 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_5.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_5.BODY_1')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_5.BODY_1_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_5.BODY_1_LI_2')}
            </Typo>
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_5.BODY_2')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_5.BODY_3')}
          </Typo>

          {/* SECTION 6 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_6.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_6.BODY')}
          </Typo>

          {/* SECTION 7 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_7.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_7.BODY')}
          </Typo>

          {/* SECTION 8 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_8.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_8.BODY_1')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_8.BODY_1_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_8.BODY_1_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_8.BODY_1_LI_3')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_8.BODY_1_LI_4')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_8.BODY_1_LI_5')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_8.BODY_1_LI_6')}
            </Typo>
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_8.BODY_2')}
          </Typo>

          {/* SECTION 9 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_9.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            {t('PRIVACY.SECTION_9.BODY')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            component="ol"
            className="pl-8 list-decimal"
            gutterBottom
          >
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_9.BODY_LI_1')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_9.BODY_LI_2')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              {t('PRIVACY.SECTION_9.BODY_LI_3')}
            </Typo>
            <Typo
              variant={i18n.language === 'th' ? 'h6' : 'body1'}
              component="li"
              gutterBottom
            >
              <I18nContactInterpol
                i18nKey="PRIVACY.SECTION_9.BODY_LI_4"
                contactType="email"
              />
            </Typo>
          </Typo>

          {/* SECTION 10 */}
          <Typo variant={i18n.language === 'th' ? 'h3' : 'h4'} gutterBottom>
            {t('PRIVACY.SECTION_10.TITLE')}
          </Typo>
          <Typo
            variant={i18n.language === 'th' ? 'h6' : 'body1'}
            paragraph={true}
            gutterBottom
          >
            <I18nContactInterpol
              i18nKey="PRIVACY.SECTION_10.BODY"
              contactType="email"
            />
          </Typo>
        </Box>
      </Container>
    </Box>
  );
}
