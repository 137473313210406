import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import CustomRadio from '../../components/field/Radio';
import ButtonPrimary from '../../components/button/Primary';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';

import CreditImg from '../../assets/images/payment/credit_card.png';
import BankingImg from '../../assets/images/payment/internet_banking.png';
import MobileBankingImg from '../../assets/images/payment/mobile_banking.png';
import TruemoneyImg from '../../assets/images/payment/truemoney.png';
import PromptPayImg from '../../assets/images/payment/prompt_pay.png';

import { useMemo } from 'react';
import { omisePayment } from '../../services/omise';

const CustomPayment = styled(Box)(({ theme, locale }) => ({
  '.MuiTypography-root:not(.header), .MuiFormControlLabel-root span.MuiTypography-root':
    {
      fontFamily:
        locale === 'th'
          ? theme.typography.font.th400
          : theme.typography.fontFamily,
    },
}));

const PaymentMethod = styled(Box)(({ theme }) => ({}));

const Image = styled('img')(({ theme, alt }) => ({
  maxWidth: '150px',
  marginTop: '8px',
}));

export default function PaymentIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const OmiseCard = useMemo(() => window.OmiseCard, []);

  const [form, setForm] = useState({});
  const [method, setMethod] = useState('');

  useEffect(() => {
    if (_.isEmpty(location.state)) {
      navigate(routePath.booking.index);
    } else setForm(location.state.data);
  }, [location, navigate]);

  useEffect(() => {
    OmiseCard.configure({
      publicKey: 'pkey_test_5nanfyudz112wzg9hwv',
      currency: 'thb',
      frameLabel: 'DOITUNG',
      submitLabel: 'pay now',
      buttonLabel: 'pay with Omise now',
    });
  }, [OmiseCard]);

  return (
    <CustomPayment
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.secondBackground} bg-brown-100 bg-blend-multiply min-h-screen flex flex-col items-center pb-8`}
      locale={i18n.language}
    >
      <Box className="w-full pt-12" sx={{ maxWidth: '984px' }}>
        <Typo
          variant="h2"
          className={`header !font-medium !text-3xl sm:!text-[40px] text-center drop-shadow`}
        >
          {t('PAYMENT.TITLE')}
        </Typo>
        <Box>
          <Typo variant="h3" className="py-8">
            {t('PAYMENT.SELECT_PAYMENT')}
          </Typo>
          <PaymentMethod className="w-full bg-white rounded-xl p-8 lg:p-12">
            <RadioGroup
              aria-labelledby="payment-method"
              // defaultValue='credit'
              className="!flex-col"
              name="payment-method"
            >
              <FormControlLabel
                value="credit_card"
                control={
                  <CustomRadio
                    solid
                    size="medium"
                    value="credit_card"
                    checked={method === 'credit_card'}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                }
                label={
                  <Box className="pl-4">
                    <Typo variant="h4">
                      1<span className="!font-en mr-1 lg:mr-3">.</span>
                      {t('PAYMENT.FORM.CREDIT')}
                    </Typo>
                    <Image src={CreditImg} alt="credit" />
                  </Box>
                }
                classes={{
                  label: '!ml-2 !text-lg sm:!text-[26px]',
                }}
              />
              <FormControlLabel
                value="internet_banking"
                className="mt-6"
                control={
                  <CustomRadio
                    solid
                    size="medium"
                    value="internet_banking"
                    checked={method === 'internet_banking'}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                }
                label={
                  <Box className="pl-4">
                    <Typo variant="h4">
                      2<span className="!font-en mr-1 lg:mr-3">.</span>
                      {t('PAYMENT.FORM.BANKING')}
                    </Typo>
                    <Image src={BankingImg} alt="banking" />
                  </Box>
                }
                classes={{ label: '!ml-2 !text-lg sm:!text-[26px]' }}
              />
              <FormControlLabel
                value="truemoney"
                className="mt-6"
                control={
                  <CustomRadio
                    solid
                    size="medium"
                    value="truemoney"
                    checked={method === 'truemoney'}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                }
                label={
                  <Box className="pl-4">
                    <Typo variant="h4">
                      3<span className="!font-en mr-1 lg:mr-3">.</span>
                      {t('PAYMENT.FORM.TRUEMONEY')}
                    </Typo>
                    <Image src={TruemoneyImg} alt="truemoney" />
                  </Box>
                }
                classes={{ label: '!ml-2 !text-lg sm:!text-[26px]' }}
              />
              <FormControlLabel
                value="promptpay"
                className=" mt-6"
                control={
                  <CustomRadio
                    solid
                    size="medium"
                    value="promptpay"
                    checked={method === 'promptpay'}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                }
                label={
                  <Box className="pl-4">
                    <Typo variant="h4">
                      4<span className="!font-en mr-1 lg:mr-3">.</span>
                      {t('PAYMENT.FORM.PROMPT_PAY')}
                    </Typo>
                    <Image src={PromptPayImg} alt="promptpay" />
                  </Box>
                }
                classes={{ label: '!ml-2 !text-lg sm:!text-[26px]' }}
              />
              <FormControlLabel
                value="mobile_banking"
                className="mt-6"
                control={
                  <CustomRadio
                    solid
                    size="medium"
                    value="mobile_banking"
                    checked={method === 'mobile_banking'}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                }
                label={
                  <Box className="pl-4">
                    <Typo variant="h4">
                      5<span className="!font-en mr-1 lg:mr-3">.</span>
                      {t('PAYMENT.FORM.MOBILE_BANKING')}
                    </Typo>
                    <Image src={MobileBankingImg} alt="mobile_banking" />
                  </Box>
                }
                classes={{ label: '!ml-2 !text-lg sm:!text-[26px]' }}
              />
            </RadioGroup>
          </PaymentMethod>
        </Box>
        <Box className="text-center mt-10">
          <Typo
            className={`!mt-8 !text-base ${
              i18n.language === 'th' ? 'md:!text-2xl' : 'md:!text-xl'
            }`}
          >
            * {t('PAYMENT.REMARK')}
          </Typo>
          <ButtonPrimary
            disabled={!method}
            classes="!my-8"
            label={t('BUTTON.NEXT')}
            onClick={() => {
              omisePayment(
                form,
                { locale: i18n.language, paymentMethod: method },
                (dataResult) => {
                  navigate(routePath.payment.receipt, {
                    state: { data: dataResult },
                  });
                }
              );
            }}
          />
        </Box>
      </Box>
    </CustomPayment>
  );
}
