import { Grid } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Typo } from '../../../assets/styles/typography';
import { getCountDataList } from '../../../services/userApi';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  layout: {
    padding: 50,
  },
  plugins: {
    legend: {
      position: null,
    },
  },
};

const labels = ['Public', 'Normal', 'Group', 'Voucher'];
const colorChart = ['#FFD8D8', '#D9FFC6', '#C6E0FF', '#F8EDB1'];

export default function DashBoard({ dateDashBoard, filterBy }) {
  const [numUserList, setNumUserList] = useState([]);
  const data = {
    labels,
    datasets: [
      {
        data: numUserList,
        backgroundColor: colorChart,
      },
    ],
  };
  const fetchData = async () => {
    const numResult = await getCountDataList(dateDashBoard.from, dateDashBoard.to, filterBy);
    setNumUserList(numResult.result);
  };

  useEffect(() => {
    fetchData();
  }, [dateDashBoard, filterBy]);

  const Tag = () => {
    return (
      <>
        {colorChart.map((_, index) => (
          <Grid container gap={1} className="xs:mb-1 md:mb-2">
            <Grid item>
              <div
                style={{
                  width: '60px',
                  height: '25px',
                  backgroundColor: colorChart[index],
                }}
              />
            </Grid>
            <Grid item>
              <Typo
                variant="subtitle1"
                className={'!mx-auto xl:!text-2xl xxl:!text-4xl'}
                sx={{ maxWidth: '390px' }}
              >
                {labels[index]}
              </Typo>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <>
      <Typo
        variant="h2"
        className={'!mx-auto xl:!text-2xl xxl:!text-4xl text-center'}
        sx={{ maxWidth: '390px' }}
      >
        จำนวนผู้เข้าชม
      </Typo>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Bar options={options} data={data} />
        </Grid>
        <Grid item xs={2}>
          <Tag />
        </Grid>
      </Grid>
    </>
  );
}
