import axios from '../configs/axios';
import moment from 'moment';

export const userBooking = async (formData) => {
  try {
    const {
      name,
      lastName,
      mobile,
      email,
      ticket,
      numberVisitor,
      tax,
      date,
      time,
      customerId,
      companyName,
      companyNameTax,
      taxIdentification,
      companyAddressTax,
      companyAddressDistrict,
      companyAddressCity,
      companyAddressCounty,
      companyAddressPostCode,
      companyNumberTax,
      emailSendTax,
    } = formData;
    const address = [
      companyAddressTax,
      companyAddressDistrict,
      companyAddressCity,
      companyAddressCounty,
      companyAddressPostCode,
    ].join(',');
    const result = await axios.post('/user/booking', {
      name: name,
      surname: lastName,
      companyName: companyName,
      tel: mobile,
      email: email,
      ticketType: ticket,
      viewer: numberVisitor,
      isTax: tax,
      date: date,
      time: time,
      paymentId: customerId,
      companyNameTax,
      taxIdentification,
      companyAddressTax: address,
      companyNumberTax,
      emailSendTax,
    });
    return result.data.result;
  } catch (err) {
    console.log(err);
  }
};

export const userAccess = async (bookingNumber, ticketNumber) => {
  try {
    const result = await axios.post('/user/access', {
      bookingNumber,
      ticketNumber,
    });
    return result.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getUserAll = async () => {
  try {
    const result = await axios.get('/user/user-all');
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCountDataList = async (dateFrom, dateTo, filterBy) => {
  try {
    const result = await axios.post('/user/count-type', { dateFrom, dateTo, filterBy });
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUserBookingById = async (id) => {
  try {
    const result = await axios.get(`/user/user-id/${id}`);
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateBooking = async (payload) => {
  try {
    const { id, date, time } = payload;
    const result = await axios.patch(`/user/update-user`, {
      id,
      date,
      time,
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};

export const downloadCsv = async ({ from, to }, filterBy) => {
  try {
    if (from && to) {
      const dateFrom = moment(from).format('YYYY-M-DD');
      const dateTo = moment(to).format('YYYY-M-DD');
      window.location.assign(
        `https://hallofinspiration.maefahluang.org/user/download?dateFrom=${dateFrom}&dateTo=${dateTo}&filterBy=${filterBy}`
      );
    } else {
      window.location.assign('https://hallofinspiration.maefahluang.org/user/download');
    }
  } catch (err) {
    console.log(err);
  }
};

export const downloadReceiptPdf = async (paymentId) => {
  const searchParams = new URLSearchParams(`paymentId=${paymentId}`);
  try {
    window.location.assign(`${axios.defaults.baseURL}/user/booking/receipt/pdf?${searchParams}`);
  } catch (err) {
    console.log(err);
  }
};
