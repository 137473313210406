import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Typo = styled(Typography)(({ theme, font}) => ({
  fontFamily: 'inherit',
  '&.MuiTypography-h1': {
    fontSize: 46,
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
    },
  },
  '&.MuiTypography-h2': {
    fontSize: 44,
    [theme.breakpoints.down('xxl')]: {
      fontSize: 38,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
  '&.MuiTypography-h3': {
    fontSize: 36,
    [theme.breakpoints.down('xxl')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  '&.MuiTypography-h4': {
    fontSize: 32,
    [theme.breakpoints.down('xxl')]: {
      fontSize: 26,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  '&.MuiTypography-h5': {
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
}));
