import { cartCheckoutPage } from './omiseApi';
import { userBooking } from './userApi';

export const omisePayment = (
  formData,
  { locale = 'en', paymentMethod },
  redirectReceipt
) => {
  const { email, name, lastName, total } = formData;
  window.OmiseCard.open({
    amount: total + '00',
    currency: 'THB',
    locale,
    defaultPaymentMethod: paymentMethod,
    otherPaymentMethods: [
      'credit_card',
      'internet_banking',
      'mobile_banking_scb',
      'mobile_banking_kbank',
      'mobile_banking_bay',
      'mobile_banking_bbl',
      'truemoney',
      'promptpay',
    ],
    onCreateTokenSuccess: (token) => {
      cartCheckoutPage(email, name, lastName, total + '00', token)
        .then((res) => {
          if (res.status === 'successful') {
            userBooking({ ...formData, customerId: res.customerId }).then(
              (res) => redirectReceipt(res)
            );
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    onFormClosed: (nonce) => {
      console.log(nonce);
    },
  });
};
// export const creditCardPayment = (formData, rediretReceipt) => {
//   const { email, name, lastName, total } = formData;
//   window.OmiseCard.open({
//     amount: total + "00",
//     currency: "THB",
//     otherPaymentMethods: "credit_card,internet_banking",
//     onCreateTokenSuccess: (token) => {
//       cartCheckoutPage(email, name, lastName, total + "00", token)
//         .then((res) => {
//           if (res.status === "successful") {
//             userBooking({ ...formData, customerId: res.customerId }).then(
//               (res) => rediretReceipt(res)
//             );
//           }
//         })
//         .catch((err) => {
//           throw err;
//         });
//     },
//     onFormClosed: (nonce) => {
//       console.log(nonce);
//     },
//   });
// };

// export const bankingPayment = (formData) => {
//   const { email, name, lastName, total } = formData;
//   window.OmiseCard.open({
//     amount: total + "00",
//     currency: "THB",
//     otherPaymentMethods: "credit_card,internet_banking",
//     onCreateTokenSuccess: (token) => {
//       cartCheckoutPage(email, name, lastName, total + "00", token);
//     },
//     onFormClosed: (nonce) => {
//       console.log(nonce);
//     },
//   });
// };
