import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloseIcon from '@mui/icons-material/Close';

const CustomTextField = styled(TextField)(({ theme, radius, font, color }) => ({
  '.MuiInputBase-root': {
    borderRadius: radius || 10,
    backgroundColor: theme.palette.brown[200],
    boxShadow: `none`,
  },
  input: {
    fontSize: 20,
    fontWeight: 700,
    padding: '0.375rem 1rem',
    fontFamily:
      font === 'en' ? theme.typography.font.en : theme.typography.font.th,
    color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
    textOverflow: 'ellipsis',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.brown[800],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  fieldset: {
    border: `2px solid ${theme.palette.brown[900]} !important`,
  },
}));

export default function InputTextSearch(props) {
  const {
    label,
    name,
    variant,
    className,
    style,
    radius,
    font,
    color,
    onChange,
    value,
    placeholder,
    onClear,
    disabledClear,
  } = props;

  return (
    <CustomTextField
      id={name}
      label={label}
      variant={variant || 'outlined'}
      size={'small'}
      radius={radius}
      font={font}
      color={color}
      fullWidth
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      InputProps={{
        endAdornment:
          value && !disabledClear ? (
            <CloseIcon
              className={'lg:!text-2xl cursor-pointer leading-none'}
              onClick={onClear}
            />
          ) : (
            <ManageSearchIcon className={'lg:!text-3xl leading-none'} />
          ),
      }}
      className={className}
      style={{ ...style }}
    />
  );
}
