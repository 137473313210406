import React from 'react';

export default function EditPencil(props) {
  const { className } = props;
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.6 9.05H0.4C0.17875 9.05 0 9.22875 0 9.45V9.9C0 9.955 0.045 10 0.1 10H9.9C9.955 10 10 9.955 10 9.9V9.45C10 9.22875 9.82125 9.05 9.6 9.05ZM1.82125 8C1.84625 8 1.87125 7.9975 1.89625 7.99375L3.99875 7.625C4.02375 7.62 4.0475 7.60875 4.065 7.59L9.36375 2.29125C9.37534 2.27969 9.38453 2.26595 9.3908 2.25083C9.39708 2.23571 9.40031 2.2195 9.40031 2.20312C9.40031 2.18675 9.39708 2.17054 9.3908 2.15542C9.38453 2.1403 9.37534 2.12656 9.36375 2.115L7.28625 0.03625C7.2625 0.0125 7.23125 0 7.1975 0C7.16375 0 7.1325 0.0125 7.10875 0.03625L1.81 5.335C1.79125 5.35375 1.78 5.37625 1.775 5.40125L1.40625 7.50375C1.39409 7.57071 1.39843 7.63963 1.41891 7.70454C1.43938 7.76945 1.47537 7.82838 1.52375 7.87625C1.60625 7.95625 1.71 8 1.82125 8Z'
        fill='#4A4A4A'
      />
    </svg>
  );
}
