import { useState } from 'react';
import { Box, Button, Slide } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../../assets/styles/typography';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import { Link } from 'react-router-dom';

export default function Cookie() {
  const { i18n, t } = useTranslation();
  const [show, setShow] = useState(true);
  const classes = useStyles();

  return (
    <Slide direction="up" in={show} mountOnEnter unmountOnExit>
      <Box
        className={`${classes.containerLayout} py-3 lg:py-0 fixed left-0 bottom-0 w-full bg-dark-900 flex items-center justify-center`}
        sx={{ minHeight: '56px', zIndex: 100 }}
      >
        <div
          className={`${
            i18n.language === 'th'
              ? 'text-lg lg:text-xl'
              : 'text-sm lg:text-base'
          }`}
        >
          <span className="text-white align-middle">{t('COOKIE_POLICY')}</span>
          <Link
            variant="text"
            className={`!text-white !capitalize !bg-transparent !font-normal !underline align-middle px-2 ${
              i18n.language === 'th'
                ? '!text-lg lg:!text-xl'
                : '!text-sm lg:!text-base'
            }`}
            to="/policy"
          >
            {t('BUTTON.READ_MORE')}
          </Link>
          <Button
            variant="contained"
            onClick={() => setShow(false)}
            className={`!capitalize !bg-white !text-dark-900 !font-normal !ml-2 ${
              i18n.language === 'th'
                ? '!text-lg lg:!text-xl'
                : '!text-sm lg:!text-base'
            }`}
          >
            {t('BUTTON.ACCEPT')}
          </Button>
        </div>
      </Box>
    </Slide>
  );
}
