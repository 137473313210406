import React from 'react';
import { Box } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../../../assets/styles/typography';
import ButtonPrimary from '../../../components/button/Primary';

// STYLE
import { styled } from '@mui/system';
import bgModal from '../../../assets/images/bg-modal.png';
import { deleteBooking } from '../../../services/bookingApi';

const CustomBody = styled(Box)(({ theme }) => ({
  maxWidth: '528px',
  minHeight: '235px',
  borderRadius: '10px',
  backgroundImage: `url(${bgModal})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export default function ModalDeleteSubmit(props) {
  const { t } = useTranslation();
  const { setOpen, setOpenDelete, bookingTarget, setDataCalendar, dataCalendar, setErrorCode } =
    props;

  const submitDelete = async () => {
    try {
      await deleteBooking(bookingTarget);
      const resultDeleteData = dataCalendar.map((dataAll) => {
        const filterData = dataAll.data.filter((data) => data.bookingId !== bookingTarget);
        return {
          date: !filterData.length ? [] : dataAll?.date,
          data: filterData,
        };
      });
      setDataCalendar(resultDeleteData);
      setOpen(false);
    } catch (err) {
      console.log(err.response.data.message);
      setErrorCode(err.response.data.message);
    }
    setOpenDelete(false);
  };

  return (
    <CustomBody className="flex items-center justify-center flex-col text-center m-auto bg-white px-2 pb-2 pt-5">
      <Typo variant="h3">
        Are you sure you want to delete this round ?
        <br />
        (After confirmation it cannot be changed.)
      </Typo>

      <Box className="mt-5">
        <ButtonPrimary
          theme="dark"
          label={t('BUTTON.PREV')}
          onClick={() => setOpenDelete(false)}
          classes="!mr-4"
        />
        <ButtonPrimary
          type="submit"
          id="checkoutButton"
          label={t('BUTTON.SUBMIT')}
          onClick={submitDelete}
          classes="!ml-4"
        />
      </Box>
    </CustomBody>
  );
}
