const typography = {
  fontFamily: "'EB Garamond', 'TH Krub', serif, sans-serif",
  font: {
    en: "'EB Garamond', serif",
    th: "'TH Krub', sans-serif",
    th400: "'TH Krub Reg', sans-serif",
    roboto: "'Roboto', sans-serif"
  },
  // h1: {
  //   fontSize: 64,
  // },
  // h2: {
  //   fontSize: 40,
  // },
  // h3: {
  //   fontSize: 32,
  // },
  // h4: {
  //   fontSize: 24,
  // },
  // h1: {
  //   fontWeight: 700,
  //   fontSize: "35px",
  //   lineHeight: "40px",
  // },
  // h2: {
  //   fontWeight: 500,
  //   fontSize: "29px",
  //   lineHeight: "32px",
  // },
  // h3: {
  //   fontWeight: 500,
  //   fontSize: "24px",
  //   lineHeight: "28px",
  // },
  // h4: {
  //   fontWeight: 700,
  //   fontSize: "20px",
  //   lineHeight: "24px",
  // },
  // h5: {
  //   fontWeight: 500,
  //   fontSize: "16px",
  //   lineHeight: "20px",
  // },
  // h6: {
  //   fontWeight: 500,
  //   fontSize: "14px",
  //   lineHeight: "20px",
  // },
  // subtitle1: {
  //   fontSize: "16px",
  //   lineHeight: "25px",
  // },
  // subtitle2: {
  //   fontWeight: 400,
  //   fontSize: "14px",
  // },
  // body1: {
  //   fontSize: "14px",
  // },
  // body2: {
  //   fontSize: "14px",
  // },
  // button: {
  //   fontSize: "14px",
  // },
  // caption: {
  //   fontSize: "12px",
  // },
  // overline: {
  //   fontSize: "12px",
  //   fontWeight: 500,
  //   textTransform: "uppercase",
  // },
};

export default typography;
