import { useState } from 'react';
import { Box } from '@mui/material';
import moment from 'moment';

// DATE PICKER
import { DayPicker } from 'react-day-picker';
import { format, differenceInCalendarDays } from 'date-fns';
import { enUS, th } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

// STYLE
import { styled } from '@mui/system';

const Calendar = styled(Box)(({ theme, type }) => ({
  backgroundColor: type === 'light' ? theme.palette.white['default'] : theme.palette.dark[800],
  boxShadow: `0px 4px 4px ${theme.palette.black[25]}`,
  borderRadius: '0 !important',
  '.rdp': {
    margin: 0,
    padding: '6px',
    '.rdp-caption_label, .rdp-head_cell > span, .rdp-button > span': {
      color: type === 'light' ? theme.palette.brown[900] : theme.palette.white['default'],
    },
    '.rdp-nav_button svg path': {
      fill: type === 'light' ? theme.palette.brown[900] : theme.palette.white['default'],
    },
    '.rdp-head_cell': {
      fontSize: '16px',
    },
    '.rdp-button': {
      // borderRadius: '4px',
      '&.rdp-nav_button': {
        backgroundColor: 'transparent !important',
        border: 'none !important',
        svg: {
          width: '13px',
          height: '13px',
        },
      },
      '&.rdp-day_range_start': {
        // borderRadius: '4px 0 0 4px',
      },
      '&.rdp-day_range_end': {
        // borderRadius: '0 4px 4px 0',
      },
      '&.rdp-day_today': {
        border: `1px solid ${theme.palette.brown[700]}`,
        // borderRadius: '4px',
      },
      '&.rdp-day_range_middle': {
        // borderRadius: 0,
      },
      '&:hover:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_range_middle)': {
        backgroundColor: theme.palette.brown[700],
        '> span': {
          color: theme.palette.white['default'],
        },

        // borderRadius: '4px',
      },
      '&.rdp-day_selected, &:active, &:focus': {
        backgroundColor: theme.palette.brown[700],
        border: `1px solid ${theme.palette.brown[700]}`,
        '> span': {
          color: theme.palette.white['default'],
        },
      },
    },
    [theme.breakpoints.down('375')]: {
      '.rdp-table': {
        maxWidth: 'calc(30px * 7)',
      },
      '.rdp-cell': {
        width: '30px',
        height: '30px',
        '.rdp-button': {
          width: '30px',
          height: '30px',
        },
      },
    },
  },
  '&.fix-allow-day': {
    '.rdp-day:not(.rdp-day_disabled)': {
      opacity: 0.25,
      pointerEvents: 'none',
    },
    '.rdp-day_disabled': {
      pointerEvents: 'auto',
      opacity: 1,
    },
  },
}));

export default function DayPickerSelect(props) {
  const { mode, theme, date, setDate, locale, allowDay, allowDays, isFetch } = props;
  const defaultSelected = {
    from: '',
    to: '',
  };
  const [range, setRange] = useState(defaultSelected);

  function isPastDate(date) {
    if (isFetch) {
      return (
        differenceInCalendarDays(date, new Date()) < 0 ||
        !allowDays.some((ele) => format(ele, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'))
      );
    } else {
      return differenceInCalendarDays(date, new Date()) < 0;
    }
  }

  return (
    <Calendar
      type={theme || 'light'}
      className={`calender-picker max-w-max ${allowDay ? 'fix-allow-day' : ''}`}
      locale={locale}
    >
      {(mode === 'range' && (
        <DayPicker
          locale={locale === 'en' ? enUS : th}
          mode={mode}
          selected={date.from && date.to ? date : range}
          onSelect={(value) => {
            setRange(value);
            setDate(value);
          }}
        />
      )) || (
        <DayPicker
          disabled={isPastDate}
          locale={locale === 'en' ? enUS : th}
          mode={mode || 'single'}
          selected={
            date.toString().includes('-') ? new Date(date.split('-').join(',')) : new Date(date)
          }
          onSelect={(value) => {
            setDate(value);
          }}
        />
      )}
    </Calendar>
  );
}
