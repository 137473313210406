import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// FORM
import {
  payment_method_credit,
  payment_method_credit_require,
} from '../../../constant/form.js';

// COMPONENT
import { Typo } from '../../../assets/styles/typography';
import ButtonPrimary from '../../../components/button/Primary';
import InputTextField from '../../../components/field/InputTextField';
import CustomModal from '../../../components/modal/CustomModal.jsx';
import ModalSubmit from '../ModalSubmit.jsx';

// ROUTE
import { routePath } from '../../../configs/route';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../../assets/styles';
import CreditImg from '../../../assets/images/payment/credit_card.png';

export default function CreditMethods(props) {
  const { data } = props;
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [form, setForm] = useState(payment_method_credit);
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleField = () => {
    if (_.every(payment_method_credit_require, (req, index) => form[req]))
      setDisabled(false);
    else setDisabled(true);
  };

  useEffect(() => {
    handleField();
  }, [form]);

  return (
    <>
      <Typo variant="h3" className="text-center pt-12 pb-8">
        {t('PAYMENT.METHOD.CREDIT.TITLE')}
      </Typo>
      <Box className="max-w-3xl mx-auto">
        <Grid
          spacing={2}
          container
          className="w-full h-full"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} className={`w-full`}>
            <Typo variant="h5">
              {t('PAYMENT.METHOD.CREDIT.FORM.NAME_ON_CARD')}
            </Typo>
            <InputTextField
              name="card_name"
              value={form.card_name}
              onChange={(e) => handleChange('card_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={`w-full`}>
            <Typo variant="h5">
              {t('PAYMENT.METHOD.CREDIT.FORM.CARD_NUMBER')}
            </Typo>
            <InputTextField
              name="card_number"
              value={form.card_number}
              onChange={(e) => handleChange('card_number', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} className={`w-full`}>
            <Typo variant="h5">
              {t('PAYMENT.METHOD.CREDIT.FORM.EXPIRATION_DATE')}
            </Typo>
            <InputTextField
              name="card_expiration"
              value={form.card_expiration}
              onChange={(e) => handleChange('card_expiration', e.target.value)}
            />
          </Grid>
          <Grid item sm={4} className={`hidden sm:block w-full`}></Grid>
          <Grid item xs={6} sm={4} className={`w-full`}>
            <Typo variant="h5">{t('PAYMENT.METHOD.CREDIT.FORM.CVC')}</Typo>
            <InputTextField
              name="card_cvc"
              value={form.card_cvc}
              onChange={(e) => handleChange('card_cvc', e.target.value)}
            />
          </Grid>
        </Grid>
        <Box className="text-center mt-16 mb-4">
          <img src={CreditImg} alt="credit" className="mx-auto" />
          <ButtonPrimary
            classes="!mt-10"
            disabled={disabled}
            label={t('BUTTON.SUBMIT')}
            onClick={() => {
              setOpen(true);
            }}
          />
        </Box>
      </Box>

      <CustomModal
        open={open}
        handleClose={(open) => {
          setOpen(open);
          navigate(routePath.payment.receipt, { state: { data: 'a' } });
        }}
        maxWidth="528px"
        body={
          <ModalSubmit
            setOpen={(open) => setOpen(open)}
            title={t('PAYMENT.METHOD.CREDIT.POPUP_MESSAGE')}
          />
        }
      />
    </>
  );
}
