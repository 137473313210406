import React from 'react';
import { Radio } from '@mui/material';

// STYLE
import { styled } from '@mui/system';

const StyledRadio = styled(Radio)(({ theme, solid, size }) => ({
  'input + span': {
    width: '20px',
    height: '20px',
    borderRadius: 100,
    backgroundColor: theme.palette.white['default'],
    // border: solid ? `2px solid ${theme.palette.brown[700]}` : 'none',
    filter: solid ? 'none' : `drop-shadow(0px 4px 4px ${theme.palette.black[25]})`,
    svg: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      fontSize: size === 'medium' ? '28px' : '20px',
      fill: theme.palette.white['default'],
      '&[data-testid="RadioButtonUncheckedIcon"]': {
        fill: solid ? theme.palette.brown[700] : theme.palette.white['default'],
      },
    },
  },
  '&.Mui-checked': {
    'input + span': {
      backgroundColor: solid ? theme.palette.brown[700] : theme.palette.white['default'],
      'svg[data-testid="RadioButtonCheckedIcon"]': {
        fill: solid ? theme.palette.brown[700] : theme.palette.brown[900],
        fontSize: '34px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    'input + span': {
      svg: {
        fontSize: '22px',
      },
    },
  },
}));

export default function CustomRadio({ value, onChange, checked, solid, size, ...rest }) {
  return (
    <StyledRadio
      {...rest}
      checked={checked}
      value={value}
      onChange={onChange}
      solid={solid}
      size={size}
    ></StyledRadio>
  );
}
