import React from 'react';

// STYLE
import logo from '../../assets/images/logo.png';
import { styled } from '@mui/system';

const Image = styled('img')(({ theme, width, responsive }) => ({
  [theme.breakpoints.down('lg')]: {
    width: responsive ? `calc(${width} - 90px)` : width,
  },
  [theme.breakpoints.down('sm')]: {
    width: responsive ? `calc(${width} - 120px)` : width,
  },
}));

export default function MainLogo(props) {
  const { size, responsive, className } = props;
  return (
    <Image
      src={logo}
      alt={'Mae fah luang Logo'}
      responsive={responsive}
      width={size || '300px'}
      className={className}
    />
  );
}
