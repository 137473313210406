import { useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import moment from 'moment';

// COMPONENT
import InputTextSearch from '../field/InputTextSearch';
import InputSearchDate from '../field/InputDate';
import BasicSelect from '../select/BasicSelect';

export default function TableSearch(props) {
  const {
    formClasses,
    search,
    date,
    status,
    setSearch,
    setDate,
    setStatus,
    styleSearch,
    styleDate,
    styleStatus,
    filterHandle = () => {},
    searchHandle,
    dateFilterHandle = () => {},
    isSearchKey = true,
    isSearchDate = true,
    isSearchSelect = true,
    menuList = [],
  } = props;
  return (
    <Box className={formClasses}>
      {isSearchKey && (
        <InputTextSearch
          placeholder="Search ticket ID/Name/Payment ID/Tax/Booking ID"
          style={{ ...styleSearch }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            searchHandle(e.target.value);
          }}
          onClear={() => {
            setSearch('');
            searchHandle('');
          }}
        />
      )}
      {isSearchDate && (
        <InputSearchDate
          mode="range"
          locale="en"
          placeholder="Search by date"
          type={'search'}
          className={'md:!mx-6'}
          style={{ ...styleDate }}
          value={date}
          setValue={(value) => {
            setDate(value);
            dateFilterHandle(value);
          }}
          onClear={() => {
            setDate('');
            dateFilterHandle('');
          }}
        />
      )}
      {isSearchSelect && (
        <BasicSelect
          id="status"
          labelId="status"
          placeholder="Search by visiting status"
          formStyle={{ ...styleStatus }}
          onChange={(e) => {
            setStatus(e.target.value);
            filterHandle(e.target.value);
          }}
          onClear={() => {
            setStatus('');
            filterHandle('');
          }}
          value={status}
          option={menuList.map((menu) => (
            <MenuItem key={menu.value} value={menu.value}>
              {menu.name}
            </MenuItem>
          ))}
        />
      )}
    </Box>
  );
}
