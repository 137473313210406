import React from "react";
import { Checkbox } from "@mui/material";

// STYLE
import { styled } from "@mui/system";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  ".MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.white["default"],
    fill: theme.palette.white["default"],
    filter: `drop-shadow(0px 4px 4px ${theme.palette.black[25]})`,
    borderRadius: 4,
  },
  "&.Mui-checked": {
    ".MuiSvgIcon-root": {
      fill: theme.palette.brown[900],
    },
  },
}));

export default function CustomCheckbox(props) {
  const { checked, onChange, disabled } = props;

  return (
    <StyledCheckbox checked={checked} onChange={onChange} disabled={disabled} />
  );
}
