import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import ButtonPrimary from '../../components/button/Primary';
import CustomModal from '../../components/modal/CustomModal';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import SecondLogo from '../../components/logo/SecondLogo';
import ReceiptImg from '../../assets/images/receipt.png';
import { datePattern } from '../../helpers/utils';

import ReactToPdf from 'react-to-pdf';
import { downloadReceiptPdf } from '../../services/userApi';

const Receipt = styled(Box)(({ theme }) => ({
  padding: '48px 32px',
  background: theme.palette.white['default'],
  boxShadow: `0px 4px 13px ${theme.palette.black[25]}`,
  backgroundImage: `url(${ReceiptImg})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '.MuiTypography-root': {
    color: theme.palette.navy,
  },
  [theme.breakpoints.down('md')]: {
    padding: '24px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
    '.logo': {
      width: '100px',
    },
  },
  [theme.breakpoints.down('375')]: {
    '.header': {
      flexDirection: 'column',
    },
  },
}));

const BoxFooter = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('425')]: {
    flexDirection: 'column',
    '.MuiBox-root': {
      '&:first-of-type': {
        order: 1,
        marginTop: '1rem',
        width: '100%',
        button: {
          width: '100%',
        },
      },
      '&:last-of-type': {
        display: 'flex',
        justifyContent: 'space-between',
        order: 0,
        width: '100%',
        button: {
          width: '48%',
          margin: '0 !important',
        },
      },
    },
  },
}));

export default function PaymentReceipt() {
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [resultData, setResultData] = useState([]);

  useEffect(() => {
    if (_.isEmpty(location.state)) {
      navigate(routePath.booking.index);
    } else {
      setResultData(location.state.data);
    }
  }, [navigate, location]);

  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [10, 2],
  };

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.secondBackground} bg-brown-100 bg-blend-multiply min-h-screen flex flex-col pb-8`}
    >
      <Box ref={ref} options={options} className="mx-auto w-full" sx={{ maxWidth: '1140px' }}>
        <Typo variant="h2" className={`text-center !font-medium py-12`}>
          {t('PAYMENT.RECEIPT.TITLE')}
        </Typo>
        <Box>
          {resultData?.map((receipt, index) => {
            return (
              <Receipt
                key={index}
                className="w-full mb-8 relative rounded-xl before:bg-white/75 before:absolute before:inset-0 before:rounded-xl"
              >
                <Box className="header relative z-1 flex items-start justify-between">
                  <SecondLogo size="180px" responsive className="logo" />
                  <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                    {t('PAYMENT.RECEIPT.TICKET.CODE')} : {receipt.dataValues.ticketCode}
                  </Typo>
                </Box>
                <Box className="body relative z-1 max-w-3xl mx-auto py-6 md:pt-14 md:pb-8">
                  <Grid spacing={2} container className="w-full h-full">
                    <Grid item md={6} className={`w-full`}>
                      <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                        {t('PAYMENT.RECEIPT.TICKET.BOOKING_NO')} : {receipt.dataValues.bookingId}
                      </Typo>
                    </Grid>
                    <Grid item md={6} className={`w-full`}>
                      <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                        {t('PAYMENT.RECEIPT.TICKET.NAME')} : {receipt.dataValues.name}{' '}
                        {receipt.dataValues.surname}
                      </Typo>
                    </Grid>
                    <Grid item md={6} className={`w-full`}>
                      <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                        {t('PAYMENT.RECEIPT.TICKET.EMAIL')} : {receipt.dataValues.email}
                      </Typo>
                    </Grid>
                    <Grid item md={6} className={`w-full`}>
                      <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                        {t('PAYMENT.RECEIPT.TICKET.DATE')} : {datePattern(receipt.dataValues.date)}{' '}
                        {t('PAYMENT.RECEIPT.TICKET.TIME')} :{' '}
                        {receipt.dataValues.startAt.slice(0, -3)} -{' '}
                        {receipt.dataValues.endAt.slice(0, -3)}
                      </Typo>
                    </Grid>
                    <Grid item md={6} className={`w-full`}>
                      <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                        {t('PAYMENT.RECEIPT.TICKET.PHONE')} : {receipt.dataValues.tel}
                      </Typo>
                    </Grid>
                    <Grid item md={6} className={`w-full`}>
                      <Typo className="!font-roboto !text-base sm:!text-xl !font-medium">
                        {t('PAYMENT.RECEIPT.TICKET.QUANTITY')} : 1
                      </Typo>
                    </Grid>
                  </Grid>
                </Box>
              </Receipt>
            );
          })}
        </Box>
        <Box className="mx-auto w-full" sx={{ maxWidth: '1140px' }}>
          <BoxFooter className="flex items-center justify-center lg:justify-between gap-6">
            <Typo variant="h6" className="w-full" lang={i18n.language}>
              {t('PAYMENT.RECEIPT.REMARK')}
            </Typo>
            <Box className="flex items-center justify-end w-full">
              <Box>
                <ButtonPrimary
                  label={t('BUTTON.BACK_TO_HOME')}
                  onClick={() => navigate(routePath.home)}
                />
              </Box>
              <Box>
                <ReactToPdf targetRef={ref} scale={0.68} x={3} y={5}>
                  {({ toPdf }) => (
                    <ButtonPrimary
                      onClick={toPdf}
                      classes="!mx-3"
                      label={t('BUTTON.DOWNLOAD.TICKET')}
                    />
                  )}
                </ReactToPdf>
              </Box>
              {!_.isEmpty(resultData[0]) && resultData[0].dataValues.isTax && (
                <Box>
                  <ButtonPrimary
                    onClick={() => downloadReceiptPdf(resultData[0]?.dataValues.paymentId)}
                    classes="!mx-3"
                    label={t('BUTTON.DOWNLOAD.RECEIPT')}
                  />
                </Box>
              )}
            </Box>
          </BoxFooter>
        </Box>
      </Box>

      <CustomModal
        open={open}
        handleClose={(open) => setOpen(open)}
        maxWidth="353px"
        body={
          <Box className="bg-white m-auto p-6 w-full max-w-[353px] rounded-lg text-center">
            <Typo variant="h3">{t('DOWNLOAD_SUCCESS')}</Typo>
          </Box>
        }
      />
    </Box>
  );
}
