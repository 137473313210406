import { useState, useEffect } from 'react';
import useStyles from '../assets/styles';
import { useLocation } from 'react-router-dom';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import Header from '../components/navbar/Header';
import LandingHeader from '../components/navbar/LandingHeader';
import Cookie from '../components/policy/Cookie';

// STYLE
import { styled } from '@mui/system';

const MainContent = styled('main')(({ theme, language }) => ({
  '*': {
    fontFamily: !language
      ? theme.typography.font.fontFamily
      : language === 'th'
      ? theme.typography.font.th
      : theme.typography.font.en,
  },
}));

function Main({ children }) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [navbarPosition, setNavbarPosition] = useState('bottom');
  const [currentPath, setCurrentPath] = useState('');

  const handleScroll = () => {
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 250) setNavbarPosition('top');
    else setNavbarPosition('bottom');
  };

  useEffect(() => {
    const params = location.pathname.substring(1).split('/');
    const param = params[0];
    setCurrentPath(param);
    if (
      param === 'admin' ||
      (param === 'booking' && params[1] === 'form') ||
      param === 'payment'
    )
      setIsLandingPage(false);
    else setIsLandingPage(true);
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {(!isLandingPage && <Header />) || (
        <LandingHeader position={!currentPath ? navbarPosition : ''} />
      )}
      <MainContent language={i18n.language} className={`${classes.fullHeight}`}>
        {children}
      </MainContent>
      <Cookie />
    </>
  );
}

export default Main;
