import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)(({ theme, radius, font, color }) => ({
  '.MuiInputBase-root': {
    borderRadius: radius || 4,
    backgroundColor: theme.palette.white['default'],
    boxShadow: `0px 4px 4px ${theme.palette.black[25]}`,
    '&.MuiInputBase-sizeSmall': {
      '& input': {
        fontSize: 24,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.dark[200],
    },
    '&.Mui-focused': {
      fieldset: {
        borderColor: `${theme.palette.brown[900]} !important`,
      },
    },
  },
  input: {
    fontSize: 24,
    fontWeight: 700,
    padding: '0.375rem 1.5rem',
    fontFamily:
      font === 'en' ? theme.typography.font.en : theme.typography.font.th,
    color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  fieldset: {
    border: '2px solid transparent !important',
  },
  '.Mui-error': {
    fieldset: {
      border: '2px solid #d32f2f !important',
    },
  },
  '.MuiFormHelperText-root': {
    fontSize: 14,
  },
}));

export default function InputTextField(props) {
  const {
    label,
    name,
    variant,
    className,
    style,
    size,
    type,
    radius,
    font,
    color,
    onChange,
    placeholder,
    trim = true,
    value,
    error,
    errorMessage,
    disabled,
  } = props;

  const handleBlur = (evt) => {
    if (!trim) return;
    evt.target.value = evt.target.value.trim();
    onChange(evt);
  };

  return (
    <CustomTextField
      id={name}
      name={name}
      label={label}
      type={type || 'text'}
      variant={variant || 'outlined'}
      size={size || 'small'}
      radius={radius}
      font={font}
      color={color}
      fullWidth
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={onChange}
      value={value}
      className={className}
      style={{ ...style }}
      error={error}
      helperText={errorMessage}
      disabled={disabled}
    />
  );
}
