import React from 'react';
import { Box, Grid } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import PrimaryHeader from '../header/PrimaryHeader';
import Footer from '../footer/Footer';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import image_first from '../../assets/images/about/Image1.png';
import image_second from '../../assets/images/about/Image2.png';
import image_third from '../../assets/images/about/Image3.png';

const Header = styled(PrimaryHeader)(({ theme }) => ({
  [theme.breakpoints.down('375')]: {
    whiteSpace: 'normal',
  },
}));

const ImageCustom = styled('img')(({ theme }) => ({
  width: '100%',
  // [theme.breakpoints.up('xxl')]: {
  //   maxWidth: '500px',
  // },
  // [theme.breakpoints.down('xxl')]: {
  //   maxWidth: '400px',
  // },
  // [theme.breakpoints.down('xl')]: {
  //   maxWidth: '350px',
  // },
}));

export default function About() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.mainBackground} pb-7 min-h-fit flex flex-col justify-center`}
    >
      <Box className={`${classes.container}`}>
        <Header />
      </Box>
      <Box
        className={
          'p-10 my-10 lg:my-0 mx-auto flex justify-center items-center flex-grow'
        }
      >
        <Grid
          container
          spacing={5}
          className="w-full h-full"
          justifyContent="space-around"
          alignContent="center"
        >
          <Grid
            item
            md={4}
            className="!max-w-[350px] xl:!max-w-[400px] xxl:!max-w-[600px] text-center"
          >
            <ImageCustom
              src={image_first}
              alt="first"
              className={`mb-6 mx-auto`}
            />
            <Typo
              variant="h5"
              className={'!mx-auto xl:!text-2xl xxl:!text-4xl'}
              sx={{ maxWidth: '390px' }}
            >
              {t('HOME.SECTION_ABOUT.SECTION_1')}
            </Typo>
          </Grid>
          <Grid
            item
            md={4}
            className="!max-w-[350px] xl:!max-w-[400px] xxl:!max-w-[600px] text-center"
          >
            <ImageCustom
              src={image_second}
              alt="second"
              className={`mb-6 mx-auto`}
            />
            <Typo
              variant="h5"
              className={'!mx-auto md:!max-w-full xl:!text-2xl xxl:!text-4xl'}
              sx={{ maxWidth: '390px' }}
            >
              {t('HOME.SECTION_ABOUT.SECTION_2')}
            </Typo>
          </Grid>
          <Grid
            item
            md={4}
            className="!max-w-[350px] xl:!max-w-[400px] xxl:!max-w-[600px] text-center md:!mb-0"
          >
            <ImageCustom
              src={image_third}
              alt="third"
              className={`mb-6 mx-auto`}
            />
            <Typo
              variant="h5"
              className={'!mx-auto md:!max-w-full xl:!text-2xl xxl:!text-4xl'}
              sx={{ maxWidth: '360px' }}
            >
              {t('HOME.SECTION_ABOUT.SECTION_3.LINE_1')}
              <br />
              {t('HOME.SECTION_ABOUT.SECTION_3.LINE_2')}
              <br />
              {t('HOME.SECTION_ABOUT.SECTION_3.LINE_3')}
            </Typo>
          </Grid>
        </Grid>
      </Box>
      <Footer className={`lg:mt-auto`} />
    </Box>
  );
}
