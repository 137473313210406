import { styled } from '@mui/system';
import { Tab, Box } from '@mui/material';

export const FragmentHeader = styled('div')(({ theme }) => ({}));

export const FragmentBody = styled('div')(({ theme }) => ({}));

export const FragmentLeft = styled('div')(({ theme }) => ({}));

export const FragmentRight = styled('div')(({ theme }) => ({}));

export const ButtonTab = styled(Tab)(({ theme }) => ({
  maxWidth: 180,
  minHeight: 'unset',
  height: 45,
  width: '100%',
  lineHeight: 'normal',
  fontFamily: theme.typography.font.th,
  fontWeight: 700,
  fontSize: 28,
  backgroundColor: theme.palette.white['default'],
  boxShadow: `0px 4px 4px ${theme.palette.black[25]}`,
  color: theme.palette.brown[900],
  padding: '0 1rem',
  borderRadius: 10,
  marginBottom: '1rem',
  '&.Mui-selected, &:hover': {
    backgroundColor: theme.palette.brown[700],
    color: theme.palette.white['default'],
  },
  [theme.breakpoints.down('lg')]: {
    '&:not(:last-of-type)': {
      marginRight: '1.5rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(:last-of-type)': {
      marginRight: '1rem',
    },
  },
}));

export const CustomTableSearch = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '> div > div': {
      maxWidth: '100% !important',
      marginTop: '1rem',
      '&:not(:first-of-type)': {
        maxWidth: '48% !important',
      },
    },
  },
}));

export const BodyBox = styled(Box)(({ theme, type }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    // minHeight: 480,
    maxWidth:
      type === 'Calendar'
        ? theme.scale.adminTab.calendar
        : theme.scale.adminTab.allMenu,
  },
  [theme.breakpoints.up('xxl')]: {
    maxWidth:
      type === 'Calendar'
        ? theme.scale.adminTab.calendar
        : theme.scale.adminTab.allMenu + 120,
  },
}));

export const BodyBoxBody = styled(Box)(({ theme, type }) => ({
  width: '100%',
  // minHeight: 340,
  backgroundColor:
    type === 'Calendar' ? 'transparent' : theme.palette.white['default'],
  boxShadow:
    type === 'Calendar' ? 'none' : `0px 4px 4px ${theme.palette.black[25]}`,
  borderRadius: 7,
}));

export const InputDate = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.input-date-wrap': {
      position: 'unset',
      '.calendar-wrap': {
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
}));
