import { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  Box,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import moment from 'moment';

// STYlE
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// ICONS
import Edit from '../../assets/icons/Edit';

// TimePicker
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import '../../assets/styles/css/time-picker.css';

const CustomTextField = styled(TextField)(
  ({ theme, radius, font, color, topic }) => ({
    '.MuiInputBase-root': {},
    input: {},
    fieldset: {},
    '.MuiInputAdornment-root': {
      marginRight: 0,
      [theme.breakpoints.down('sm')]: {
        marginRight: '-4px',
      },
    },
    '&.Mui-theme-dark': {
      '.MuiInputBase-root': {
        backgroundColor: theme.palette.dark[800],
      },
      svg: {
        fill: theme.palette.white['default'],
      },
    },
  })
);

const TimePickerStyle = styled(Box)(
  ({ theme, font, color, radius, topic }) => ({
    '.rc-time-picker-input': {
      height: 40.75,
      border: `2px solid ${theme.palette.brown[900]} !important`,
      borderRadius: radius || 10,
      backgroundColor:
        topic === 'search'
          ? theme.palette.brown[200]
          : theme.palette.white['default'],
      boxShadow: `none`,
      cursor: 'pointer',
      fontSize: 20,
      fontWeight: 700,
      padding: '0.375rem 1rem',
      fontFamily:
        font === 'en' ? theme.typography.font.en : theme.typography.font.th,
      color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
      textOverflow: 'ellipsis',
      pointerEvents: 'none',
      caretColor: 'transparent',
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.brown[800],
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        height: 35,
      },
    },
    '.rc-time-picker-clear': {
      display: 'none',
    },
  })
);

export default function InputDate(props) {
  const { radius, font, color, type, onChange, value } = props;

  return (
    <TimePickerStyle
      font={font}
      color={color}
      radius={radius}
      topic={type}
      className={`mt-[10px] z-1 cursor-pointer relative`}
    >
      <TimePicker
        showSecond={false}
        popupClassName='timePicker-popup'
        value={value ? moment(value, 'HH:mm') : null}
        defaultOpenValue={moment()}
        onChange={onChange}
      />
      <AccessTimeIcon
        className={
          'lg:!text-2xl leading-none absolute top-1/2 right-3 -translate-y-1/2'
        }
      />
    </TimePickerStyle>
  );
}
