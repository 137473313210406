import React from 'react';
import { Button } from '@mui/material';

export default function ButtonText(props) {
  const { label, onClick, classes, fontSize } = props;

  return (
    <Button
      variant='text'
      disableRipple
      onClick={onClick}
      className={`${
        fontSize || '!text-xl'
      } !p-0 !min-w-fit !text-black-default ${classes}`}
    >
      {label}
    </Button>
  );
}
