import React from 'react';
import { Box } from '@mui/material';

import { HashLink } from 'react-router-hash-link';

import { routePath } from '../../configs/route';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import { Typo } from '../../assets/styles/typography';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import Background from '../../assets/images/home/background.png';
import ArrowDown from '../../assets/images/home/arrow-down.svg';

const HomeSection = styled('div')(({ theme }) => ({
  backgroundImage: `url(${Background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: theme.palette.black[40],
  backgroundBlendMode: 'multiply',
}));

const AnimatedStartTour = styled(Box)({
  '@keyframes up-down': {
    '0%': {
      transfrom: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(8px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  animation: 'up-down 3s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)',
});

export default function Home() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <HomeSection
      className={`${classes.containerLayout} flex items-center justify-center flex-col min-h-screen pt-12`}
    >
      <Box className={`mt-3 mb-5`}>
        <Typo variant="h1" className={`text-white !font-thasadith italic text-center`}>
          {t('HOME.SECTION_HOME.TITLE.LINE_1')}
          <br />
          {t('HOME.SECTION_HOME.TITLE.LINE_2')}
        </Typo>
      </Box>
      <Box>
        <Typo className={`text-white !font-thasadith text-center !font-bold !text-lg md:!text-2xl`}>
          {t('HOME.SECTION_HOME.SUBTITLE.LINE_1')}
          <br className={`hidden sm:block`} />
          {t('HOME.SECTION_HOME.SUBTITLE.LINE_2')}
        </Typo>
      </Box>
      <AnimatedStartTour className="mt-20">
        <HashLink to={routePath.hash.visit} smooth>
          <Typo variant="h3" className=" text-[#FCD869]">
            {t('HOME.SECTION_HOME.START_TOUR')}
          </Typo>
          <img className="mx-auto my-5" src={ArrowDown} alt="Arrow down icon" />
        </HashLink>
      </AnimatedStartTour>
    </HomeSection>
  );
}
