import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import THEME from "./assets/themes/index";
import Routes from "./routes/Router";

import "tailwindcss/tailwind.css";

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <BrowserRouter>
        <CssBaseline />
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
