export const booking_form = {
  name: '',
  lastName: '',
  mobile: '',
  email: '',
  ticket: '',
  code: '',
  numberVisitor: 1,
  total: 0,
  tax: false,
  companyName: '',
  companyNameTax: '',
  taxIdentification: '',
  companyAddressTax: '',
  companyAddressCity: '',
  companyAddressDistrict: '',
  companyAddressCounty: '',
  companyAddressPostCode: '',
  companyNumberTax: '',
  date: '',
  time: '',
};

export const payment_method_credit = {
  card_name: '',
  card_number: '',
  card_expiration: '',
  card_cvc: '',
};

export const personal_require = [
  'name',
  'lastName',
  'mobile',
  'email',
  'ticket',
  'time',
  'date',
  'companyName',
];

export const tax_require = [
  'companyNameTax',
  'taxIdentification',
  'companyAddressTax',
  'companyAddressCity',
  'companyAddressDistrict',
  'companyAddressCounty',
  'companyAddressPostCode',
  'companyNumberTax',
];

export const payment_method_credit_require = [
  'card_name',
  'card_number',
  'card_expiration',
  'card_cvc',
];
