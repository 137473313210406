import { useState, useEffect } from 'react';
import { TextField, InputAdornment, Box, ClickAwayListener, IconButton } from '@mui/material';
import moment from 'moment';

// STYlE
import { styled } from '@mui/system';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// ICONS
import Edit from '../../assets/icons/Edit';

// COMPONENT
import DayPicker from '../DatePicker/DayPicker';

const CustomTextField = styled(TextField)(({ theme, radius, font, color, topic }) => ({
  '.MuiInputBase-root': {
    borderRadius: radius || 10,
    backgroundColor: topic === 'search' ? theme.palette.brown[200] : theme.palette.white['default'],
    boxShadow: `none`,
    cursor: 'pointer',
  },
  input: {
    fontSize: 20,
    fontWeight: 700,
    padding: '0.375rem 1rem',
    fontFamily: font === 'en' ? theme.typography.font.en : theme.typography.font.th,
    color: color === 'dark' ? theme.palette.navy : theme.palette.brown[900],
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
    caretColor: 'transparent',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.brown[800],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  fieldset: {
    border: `2px solid ${theme.palette.brown[900]} !important`,
  },
  '.MuiInputAdornment-root': {
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: '-4px',
    },
  },
  '&.Mui-theme-dark': {
    '.MuiInputBase-root': {
      backgroundColor: theme.palette.dark[800],
    },
    svg: {
      fill: theme.palette.white['default'],
    },
  },
}));

const Calendar = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  zIndex: 10,
}));

export default function InputDate(props) {
  const {
    label,
    name,
    variant,
    className,
    style,
    radius,
    font,
    color,
    onClear,
    placeholder,
    value,
    type,
    theme,
    inputFormat,
    disabledClear,
    mode,
    setValue,
    locale,
    dateAllow = [],
    isFetch = false,
  } = props;

  const [open, setOpen] = useState(false);

  const renderDate = (mode, value) => {
    if (mode === 'range' && value.from && value.to) {
      return `${moment(value.from).format(inputFormat || 'YYYY-MM-DD')} - ${moment(value.to).format(
        inputFormat || 'YYYY-MM-DD'
      )}`;
    } else if (mode !== 'range' && value) {
      return moment(value).format(inputFormat || 'YYYY-MM-DD');
    } else return '';
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        className={`${className ? className : ''} relative !h-full cursor-pointer input-date-wrap`}
        onClick={() => setOpen(true)}
      >
        {(type === 'edit' && (
          <IconButton aria-label="date">
            <Edit className={'w-4 h-4 fill-brown-900'} />
          </IconButton>
        )) || (
          <CustomTextField
            id={name}
            label={label}
            variant={variant || 'outlined'}
            size={'small'}
            radius={radius}
            font={font}
            color={color}
            fullWidth
            className={`${theme === 'dark' ? 'Mui-theme-dark' : ''} cursor-pointer`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {(value && !disabledClear && (
                    <CloseIcon
                      className={'lg:!text-2xl cursor-pointer leading-none'}
                      onClick={onClear}
                    />
                  )) ||
                    (type === 'search' && (
                      <ManageSearchIcon className={'lg:!text-3xl leading-none'} />
                    )) || <EventIcon className={'lg:!text-2xl leading-none'} />}
                </InputAdornment>
              ),
              placeholder: placeholder,
            }}
            style={{ ...style }}
            topic={type}
            value={renderDate(mode, value)}
          />
        )}

        {open ? (
          <Calendar className="absolute calendar-wrap">
            <DayPicker
              isFetch={isFetch}
              locale={locale}
              mode={mode}
              theme="light"
              date={value}
              setDate={(value) => (value ? setValue(value) : '')}
              allowDays={dateAllow}
            />
          </Calendar>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
}
