import React, { useEffect } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';

// COMPONENT
// import FristSection from '../components/sections/Frist';
// import SecondSection from '../components/sections/Second';
import Home from '../components/sections/Home';
import About from '../components/sections/About';
import Visit from '../components/sections/Visit';
import History from '../components/sections/History';
import Contact from '../components/sections/Contact';

// CONSTANT
import { navbar } from '../constant';

export default function Landing() {
  return (
    <>
      {_.map(navbar, (nav, index) => {
        return (
          <Box key={index} id={nav.id}>
            {index === 0 && <Home />}
            {index === 1 && <About />}
            {index === 2 && <Visit />}
            {index === 3 && <History />}
            {index === 4 && <Contact />}
          </Box>
        );
      })}
    </>
  );
}
