import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import InputTextField from '../../components/field/InputTextField';
import ButtonPrimary from '../../components/button/Primary';

// STYLE
import { styled } from '@mui/system';
import useStyles from '../../assets/styles';
import { userAccess } from '../../services/userApi';
import { setCookie } from '../../helpers/utils';

const Booking = styled(Box)(({ theme }) => ({
  '.MuiFormControl-root': {
    [theme.breakpoints.up('sm')]: {
      // maxWidth: '348px',
    },
  },
}));

const formTemplate = {
  bookingNumber: '',
  ticketNumber: '',
};

export default function BookingIndex() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({
    ...formTemplate,
  });

  const [errorCode, setErrorCode] = useState('');

  const handleChange = (evt) => {
    if (errorCode) return setErrorCode('');
    const { name, value } = evt.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    const { bookingNumber, ticketNumber } = form;
    try {
      const accessResult = await userAccess(bookingNumber, ticketNumber);
      if (accessResult?.isSuccess) {
        setCookie('token', accessResult.accessToken, accessResult.endTime);
        window.location.replace(
          'https://hallofinspiration.maefahluang.org/special/'
        );
      }
    } catch (err) {
      setErrorCode(err.code);
    }
  };

  return (
    <Booking
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.mainBackground} min-h-screen`}
    >
      <Box className="flex flex-col justify-center items-center gap-4">
        <Typo
          variant="h2"
          className={`text-center pb-8 sm:pb-28 lg:py-8 !text-2xl sm:!text-4xl xxl:!text-[40px]`}
        >
          {t('BOOKING.INDEX.TITLE_1')}
          <br className={`hidden sm:block`} />
          {t('BOOKING.INDEX.TITLE_2')}
        </Typo>
        <Box className="mb-12 sm:mb-28 lg:mb-8 lg:mt-auto">
          <Box
            className={`flex flex-col sm:items-center max-w-xl mx-auto sm:justify-between sm:flex-row`}
          >
            <Typo variant="h4" className="w-[280px] !mr-8">
              {t('BOOKING.INDEX.CODE')}
            </Typo>
            <InputTextField name="bookingNumber" onChange={handleChange} />
          </Box>
          <Box
            className={`flex flex-col mt-6 max-w-xl sm:items-center mx-auto sm:justify-between sm:flex-row`}
          >
            <Typo variant="h4" className="w-[280px] !mr-8">
              {t('BOOKING.INDEX.TICKET_NUMBER')}
            </Typo>
            <InputTextField name="ticketNumber" onChange={handleChange} />
          </Box>
          <Typo variant="h6" className="text-center text-red-500 !mt-8">
            {errorCode ? t(`BOOKING.ERR.${errorCode}`) : ''}
          </Typo>
          <Box className={'text-center mt-6'}>
            <ButtonPrimary
              label={t('BUTTON.VISIT')}
              disabled={!form.bookingNumber || !form.ticketNumber}
              onClick={handleSubmit}
            />
          </Box>
        </Box>
        <Box className="text-center lg:mt-auto">
          <Typo variant="h5" className={'!mb-6'}>
            {t('BOOKING.INDEX.NO_BOOKING_NUMBER')}
            <br />
            {t('BOOKING.INDEX.CLICK_FOR_BOOKING')}
          </Typo>
          <ButtonPrimary
            label={t('BUTTON.GO_BOOKING')}
            onClick={() => navigate(routePath.booking.form)}
            style={{ minWidth: '235px' }}
          />
        </Box>
      </Box>
    </Booking>
  );
}
