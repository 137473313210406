import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Grid, Chip, MenuItem, Button, IconButton } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

// COMPONENT
import { Typo } from '../../../assets/styles/typography';
import ButtonPrimary from '../../../components/button/Primary';
import InputDate from '../../../components/field/InputDate';
import InputTime from '../../../components/field/InputTime';
import BasicSelect from '../../../components/select/BasicSelect';
import InputCount from '../../../components/field/InputCount';

// STYLE
import { styled } from '@mui/system';
import { createBooking, updateBooking } from '../../../services/bookingApi';
import { useTranslation } from 'react-i18next';

const CustomModal = styled(Box)(({ theme }) => ({
  '.MuiInputBase-input': {
    // color: theme.palette.white['default'],
  },
  '.MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
  },
  '.input-viewer': {
    '> div': {
      maxWidth: '100%',
      boxShadow: 'none',
      border: `2px solid ${theme.palette.brown[900]}`,
      borderRadius: '10px',
      '.MuiOutlinedInput-root': {
        backgroundColor: 'transparent',
      },
      button: {
        width: '25%',
        '&:first-of-type': {
          borderRight: `2px solid ${theme.palette.brown[900]}`,
          borderRadius: 0,
        },
        '&:last-of-type': {
          borderLeft: `2px solid ${theme.palette.brown[900]}`,
          borderRadius: 0,
        },
      },
    },
  },
  '.btn-confirm': {
    width: '100%',
    maxWidth: '214px',
    '&:disabled': {
      opacity: 0.7,
    },
    '&:not(:disabled)': {
      backgroundColor: theme.palette.dark[800],
    },
  },
  // font-size
  '.rc-time-picker-input': {
    fontSize: '22px !important',
  },
  '.MuiFormControl-root': {
    '.MuiOutlinedInput-root': {
      height: 40,
      '.MuiOutlinedInput-input': {
        fontSize: '22px',
      },
      '.MuiSelect-select': {
        minHeight: 'unset',
      },
    },
  },
}));

export default function ModalAddRounds(props) {
  const {
    setOpen,
    setOpenDelete,
    setBookingTarget,
    data,
    setIsFetch,
    isFetch,
    isCreate,
    errorCode,
    setErrorCode,
  } = props;
  const objTime = {
    start: '',
    end: '',
  };
  const { t } = useTranslation();
  const [languages, setLanguages] = useState(['Thai', 'English']);
  const [statusRound, setStatusRound] = useState(['opened', 'closed']);
  const [date, setDate] = useState('');
  const [time, setTime] = useState(objTime);
  const [language, setLanguage] = useState(languages[0]);
  const [viewer, setViewer] = useState(1);
  const [status, setStatus] = useState(statusRound[0]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const newDate = new Date();
      const timeStartAt = new Date(
        newDate.setHours(data.round.from.slice(0, 2), data.round.from.slice(3, 5))
      );
      const timeEndAt = new Date(
        newDate.setHours(data.round.to.slice(0, 2), data.round.to.slice(3, 5))
      );
      setBookingTarget(data.bookingId);
      setDate(data.date);
      setTime({
        start: { _d: timeStartAt },
        end: { _d: timeEndAt },
      });
      setLanguage(data.language);
      setViewer(data.viewer);
      setStatus(data.status);
    }
  }, [data]);

  const handleSubmit = async () => {
    const hourStart = new Date(time.start._d).getHours();
    const minutesStart = new Date(time.start._d).getMinutes();
    const hourEnd = new Date(time.end._d).getHours();
    const minutesEnd = new Date(time.end._d).getMinutes();
    try {
      if (isCreate) {
        await createBooking({
          date,
          timeStartAt: `${hourStart}:${minutesStart}`,
          timeEndAt: `${hourEnd}:${minutesEnd}`,
          languages: language,
          viewer,
          status: status === 'opened',
        });
        setOpen(false);
      } else {
        await updateBooking({
          bookingId: data.bookingId,
          date,
          timeStartAt: `${hourStart}:${minutesStart}`,
          timeEndAt: `${hourEnd}:${minutesEnd}`,
          languages: language,
          viewer,
          status: status === 'opened',
        });
        setOpen(false);
      }
    } catch (err) {
      setOpen(true);
      setErrorCode(err.response.data.message);
    }
    setIsFetch(!isFetch);
  };

  return (
    <CustomModal className={'admin mx-auto bg-white rounded-lg'} sx={{ maxWidth: 463 }}>
      <Box className={'bg-dark-800 py-2 text-center rounded-tl-lg rounded-tr-lg'}>
        <Typo variant="h4" font="en" className={'text-white'}>
          Create show date & time
        </Typo>
      </Box>

      <Box className={'p-4 sm:py-6 sm:px-8'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typo variant="h4" font="en" className={'text-dark-800 pt-2'}>
              Date
            </Typo>
            <InputDate
              value={date}
              setValue={(value) => setDate(value)}
              locale="en"
              disabledClear
            />
          </Grid>
          <Grid item xs={6} className={'!pr-3'}>
            <Typo variant="h4" font="en" className={'text-dark-800'}>
              From
            </Typo>
            <InputTime
              value={time.start._d}
              disabledClear
              onChange={(value) => setTime({ ...time, start: { _d: value } })}
            />
          </Grid>
          <Grid item xs={6} className={'!pl-3'}>
            <Typo variant="h4" font="en" className={'text-dark-800'}>
              To
            </Typo>
            <InputTime
              value={time.end._d}
              disabledClear
              onChange={(value) => setTime({ ...time, end: { _d: value } })}
            />
          </Grid>
          <Grid item xs={6} className={'!pr-3'}>
            <Typo variant="h4" font="en" className={'text-dark-800'}>
              Languages
            </Typo>
            <BasicSelect
              id="languages"
              labelId="languages"
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
              value={language}
              option={_.map(languages, (val, index) => {
                return (
                  <MenuItem key={index} value={val}>
                    {val === 'Thai' ? 'ภาษาไทย' : 'English'}
                  </MenuItem>
                );
              })}
              disabledClear
            />
          </Grid>
          <Grid item xs={6} className={'!pl-3'}>
            <Typo variant="h4" font="en" className={'text-dark-800'}>
              Viewer
            </Typo>
            <Box className="input-viewer">
              <InputCount
                value={viewer}
                min={1}
                max={20}
                onChange={(e) => {
                  const min = 1;
                  const max = 20;
                  const value = e.target.value;
                  if (value <= min) setViewer(min);
                  else if (value >= max) setViewer(max);
                  else setViewer(value);
                }}
                setValue={(value) => setViewer(value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} className={'!pl-3'}>
            <Typo variant="h4" font="en" className={'text-dark-800'}>
              Status
            </Typo>
            <BasicSelect
              id="status"
              labelId="status"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
              option={_.map(statusRound, (val, index) => {
                return (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                );
              })}
              disabledClear
            />
          </Grid>
          {isCreate || (
            <Grid item xs={6} className={'!pl-3'}>
              <Typo variant="h4" font="en" className={'text-dark-800'}>
                Delete
              </Typo>

              <IconButton aria-label="delete" onClick={() => setOpenDelete(true)}>
                <DeleteRoundedIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Typo variant="h6" className="text-center text-red-500 !mt-4">
          {errorCode ? t(`BOOKING_ADMIN_ACTION.${errorCode}`) : ''}
        </Typo>
      </Box>

      <Box className={'block pt-2 pb-6 px-4 text-center'}>
        <ButtonPrimary
          disabled={!date || !time.start._d || !time.end._d || !language || !viewer || !status}
          theme="dark"
          label="Confirm"
          font="th"
          classes={'!shadow-none btn-confirm flex-1'}
          onClick={handleSubmit}
        />
      </Box>
    </CustomModal>
  );
}
