import { useState } from "react";
import useStyles from "../../assets/styles";
import { Box } from "@mui/material";

// COMPONENT
import InputTextField from "../../components/field/InputTextField";
import ButtonPrimary from "../../components/button/Primary";

// STYLE
import { Typo } from "../../assets/styles/typography";
import { loginAdmin } from "../../services/adminApi";
import { setToken } from "../../helpers/LocalStorageService";

export default function Login() {
  const classes = useStyles();
  const loginForm = { username: "", password: "" };
  const [form, setForm] = useState(loginForm);

  const onSubmit = async () => {
    const loginRespone = await loginAdmin(form);
    if (loginRespone?.accessToken) {
      setToken(loginRespone?.accessToken)
      window.location.reload()
    }
  };

  return (
    <div
      className={`admin ${classes.secondBackground} ${classes.containerLayout} bg-brown-100 bg-blend-multiply flex items-center justify-center flex-col py-92px min-h-full overflow-y-auto overflow-x-hidden`}
    >
      <Typo
        variant="h2"
        font="en"
        className={
          "pt-8 !font-bold !font-th text-center !mb-8 sm:!mb-32 lg:!mb-16"
        }
      >
        Admin
      </Typo>
      <Box
        component="div"
        autoComplete="off"
        className={"flex flex-col mt-8 sm:flex-row sm:items-center"}
      >
        <Typo
          variant="h3"
          font="en"
          className={"!font-bold !font-th pr-1 flex-none"}
          style={{ width: 150 }}
        >
          Username
          <Typo variant="span" className={"pl-2"}>
            :
          </Typo>
        </Typo>
        <InputTextField
          name={"username"}
          size="medium"
          radius={10}
          onChange={(e) =>
            setForm({ ...form, ...{ username: e.target.value } })
          }
          className={"sm:!ml-12 lg:!w-[380px]"}
        />
      </Box>
      <Box
        component="div"
        autoComplete="off"
        className={"flex flex-col mt-8 mb-14 sm:flex-row sm:items-center"}
      >
        <Typo
          variant="h3"
          font="en"
          className={"!font-bold !font-th pr-1 flex-none"}
          style={{ width: 150 }}
        >
          Password
          <Typo variant="span" font="en" className={"pl-2"}>
            :
          </Typo>
        </Typo>
        <InputTextField
          name={"password"}
          size="medium"
          type="password"
          radius={10}
          onChange={(e) =>
            setForm({ ...form, ...{ password: e.target.value } })
          }
          className={"sm:!ml-12 lg:!w-[380px]"}
        />
      </Box>
      <ButtonPrimary
        label={"OK"}
        disabled={!form.username || !form.password}
        onClick={onSubmit}
        classes="!mt-16 sm:!mt-64 lg:!mt-auto xxl:!mt-16"
      />
    </div>
  );
}
