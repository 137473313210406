import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = 'https://hallofinspiration.maefahluang.org';

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response?.status === 401) {
      console.log('err');
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export default axios;
