import React from "react";
import { Box } from "@mui/material";

// TRANSLATION
import { useTranslation } from "react-i18next";

// COMPONENT
import { Typo } from "../../assets/styles/typography";
import ButtonPrimary from "../../components/button/Primary";

// STYLE
import { styled } from "@mui/system";
import bgModal from "../../assets/images/bg-modal.png";

const CustomBody = styled(Box)(({ theme }) => ({
  maxWidth: "528px",
  minHeight: "235px",
  borderRadius: "10px",
  backgroundImage: `url(${bgModal})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

export default function ModalSubmit(props) {
  const { t } = useTranslation();
  const { setOpen, ticket, onSubmit } = props;

  return (
    <CustomBody className="flex items-center justify-center flex-col text-center m-auto bg-white px-2 pb-2 pt-5">
      {(ticket === "voucher" && (
        <Typo variant="h3">
          {t("BOOKING.MODAL.MESSAGE_CHECK_WHEN_CODE")}
          <br />
          {t("BOOKING.MODAL.MESSAGE_CHECK_WHEN_CODE_REMARK")}
        </Typo>
      )) || (
        <Typo variant="h3">
          {t("BOOKING.MODAL.MESSAGE_FORM")}
          <br />
          {t("BOOKING.MODAL.MESSAGE_CONTINUE")}
        </Typo>
      )}

      <Box className="mt-5">
        <ButtonPrimary
          theme="dark"
          label={t("BUTTON.PREV")}
          onClick={() => setOpen(false)}
          classes="!mr-4"
        />
        <ButtonPrimary
          type="submit"
          id="checkoutButton"
          label={t("BUTTON.SUBMIT")}
          onClick={onSubmit}
          classes="!ml-4"
        />
      </Box>
    </CustomBody>
  );
}
