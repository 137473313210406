import React from 'react';
import _ from 'lodash';
import { TableRow, TableCell, IconButton, Chip, MenuItem } from '@mui/material';

// ICONS
import Edit from '../../assets/icons/Edit';

// STYLE
import { styled } from '@mui/system';

// COMPONENT
import BasicSelect from '../select/BasicSelect';

const Status = styled(Chip)(({ theme, type }) => ({
  backgroundColor:
    type === 'visited'
      ? theme.palette.blue
      : type === 'booked'
      ? theme.palette.green
      : type === 'expired'
      ? theme.palette.red
      : theme.palette.brown[100],
  border: `2px solid ${theme.palette.brown[900]}`,
  borderRadius: 10,
  fontSize: 20,
  width: 80,
}));

export default function CustomTableRow({ columns, onEdit, onChange, ...rest }) {
  return (
    <TableRow {...rest}>
      {_.map(columns, (row, index) => {
        return (
          (row.type === 'edit' && (
            <TableCell
              key={index}
              scope="row"
              align={row.align}
              className={'!font-th'}
            >
              <IconButton
                aria-label={row.type}
                onClick={() => onEdit(row.label)}
              >
                <Edit className={'w-5 h-5 fill-brown-900'} />
              </IconButton>
            </TableCell>
          )) ||
          (row.type === 'status' && (
            <TableCell
              key={index}
              scope="row"
              align={row.align}
              className={'!font-th'}
            >
              <Status
                type={row.label}
                label={row.label}
                className={'!font-th font-bold capitalize'}
              />
            </TableCell>
          )) ||
          (row.type === 'select' && (
            <TableCell
              key={index}
              scope="row"
              align={row.align}
              className={'!font-th'}
            >
              <BasicSelect
                id="access"
                labelId="access"
                selectClassName="Mui-Select-root-access"
                onChange={(e) => onChange(e.target.value, row.id)}
                value={row.label}
                option={_.map(row.option, (option, index) => {
                  return (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
                formStyle={{ maxWidth: 120 }}
                disabledClear
              />
            </TableCell>
          )) || (
            <TableCell
              key={index}
              scope="row"
              align={row.align}
              className={'!font-th'}
            >
              {row.label}
            </TableCell>
          )
        );
      })}
    </TableRow>
  );
}
