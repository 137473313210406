import { useEffect, useState } from 'react';
import _, { create, filter } from 'lodash';
import { Box, IconButton } from '@mui/material';

// COMPONENT
import CustomTable from '../../../components/table/CustomTable';
import TableRow from '../../../components/table/CustomTableRow';
import CustomModal from '../../../components/modal/CustomModal';
import ModalAddVoucher from './ModalAddVoucher';

import { datePattern } from '../../../helpers/utils';
import { getAllVoucher, updateVoucherStatus } from '../../../services/voucherApi';

import InputDate from '../../../components/field/InputDate';
import moment from 'moment';

import { History, Delete } from '@mui/icons-material';

export default function Voucher(props) {
  const { open, onClose } = props;
  const [page, setPage] = useState(1);
  const [voucherList, setVoucherList] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [filterDate, setFilterDate] = useState('');
  const [filteredVouchers, setFilteredVouchers] = useState([]);

  const filterVoucherDateHandle = (filterDateValue) => {
    setFilterDate(filterDateValue);
    let filtered;
    if (filterDate !== '') {
      filtered = voucherList.filter((voucher) => {
        const createdAt = moment(voucher.createdAt);
        const startDate = moment(filterDateValue.from);
        const endDate = moment(filterDateValue.to);
        return moment(createdAt).isBetween(startDate, endDate, 'day', '[]');
      });
    } else {
      filtered = voucherList;
    }

    setFilteredVouchers(filtered);
  };

  const clearFilterValue = () => {
    setFilteredVouchers(voucherList);
    setFilterDate('');
  };

  const fetchData = async () => {
    const resultVoucher = await getAllVoucher();
    setVoucherList(resultVoucher.result);
    setFilteredVouchers(resultVoucher.result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableHead = [
    {
      id: 'voucherNumber',
      label: 'Voucher number',
      align: 'center',
      width: '',
    },
    { id: 'redeemStatus', label: 'Redeem status', align: 'center', width: '' },
    { id: 'expiredDate', label: 'Expired date', align: 'center', width: '' },
    { id: 'createdAt', label: 'Created date', align: 'center', width: '' },
    { id: 'action', label: 'Action', align: 'center', width: '' },
  ];

  const handleUpdateStatus = async (voucherNumber, redeemStatus) => {
    try {
      await updateVoucherStatus(voucherNumber, redeemStatus);
      const toggleStatus = redeemStatus === 'Cancelled' ? 'Activated' : 'Cancelled';
      const newVoucherData = filteredVouchers.map((data) =>
        data.voucherNumber === voucherNumber ? { ...data, redeemStatus: toggleStatus } : data
      );
      setFilteredVouchers(newVoucherData);
    } catch (err) {
      console.log(err);
    }
  };

  const IconActionButton = ({ children, voucherNumber, redeemStatus }) => {
    return (
      <IconButton
        color="primary"
        aria-label="action"
        component="label"
        onClick={() => handleUpdateStatus(voucherNumber, redeemStatus)}
      >
        {children}
      </IconButton>
    );
  };

  const renderData = (data, index) => {
    const { voucherNumber, redeemStatus, expiredDate, createdAt } = data;
    const render = {
      columns: [
        { type: 'text', align: 'center', label: voucherNumber || '-' },
        { type: 'text', align: 'center', label: redeemStatus || '-' },
        {
          type: 'date',
          align: 'center',
          label: datePattern(expiredDate) || '-',
        },
        {
          type: 'date',
          align: 'center',
          label: datePattern(createdAt) || '-',
        },
        {
          type: 'icon',
          align: 'center',
          label:
            redeemStatus === 'Cancelled' ? (
              <IconActionButton voucherNumber={voucherNumber} redeemStatus={redeemStatus}>
                <History />
              </IconActionButton>
            ) : redeemStatus === 'Activated' ? (
              <IconActionButton voucherNumber={voucherNumber} redeemStatus={redeemStatus}>
                <Delete />
              </IconActionButton>
            ) : (
              '-'
            ),
        },
      ],
    };
    return (
      <TableRow
        className={`${
          redeemStatus === 'Activated' ? '' : redeemStatus === 'Used' ? 'has-green' : 'has-red'
        }`}
        {...render}
        key={index}
      />
    );
  };

  return (
    <>
      <InputDate
        mode="range"
        locale="en"
        placeholder="Search by created date"
        type={'search'}
        className={'md:!mx-6'}
        value={filterDate}
        setValue={filterVoucherDateHandle}
        onClear={clearFilterValue}
      />
      <CustomTable
        page={page}
        dataCount={filteredVouchers.length}
        setPage={(page) => setPage(page)}
        tableMinWidth={1020}
        tableHead={tableHead}
        tableBody={_.map(
          filteredVouchers.slice((page - 1) * rowPerPage, (page - 1) * rowPerPage + rowPerPage),
          (val, index) => {
            return renderData(val, index);
          }
        )}
      />
      <CustomModal
        open={open}
        handleClose={(open) => onClose(open)}
        maxWidth="463px"
        body={
          <ModalAddVoucher
            voucherList={voucherList}
            setVoucherList={setVoucherList}
            setOpen={(open) => onClose(open)}
          />
        }
      />
    </>
  );
}
