// ROUTE
import { routePath } from '../configs/route';

export const navbar = [
  {
    id: 'home',
    name: 'NAVBAR.HOME',
    path: routePath.hash.home,
  },
  {
    id: 'about',
    name: 'NAVBAR.ABOUT',
    path: routePath.hash.about,
  },
  {
    id: 'visit',
    name: 'NAVBAR.VISITING',
    path: routePath.hash.visit,
  },
  {
    id: 'history',
    name: 'NAVBAR.HISTORY',
    path: routePath.hash.history,
  },
  {
    id: 'contact',
    name: 'NAVBAR.CONTACT',
    path: routePath.hash.contact,
  },
];
