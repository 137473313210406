import React from "react";
import { Box } from "@mui/material";

// TRANSLATION
import { useTranslation } from "react-i18next";

// COMPONENT
import { Typo } from "../../assets/styles/typography";

// STYLE
import { styled } from "@mui/system";
import bgModal from "../../assets/images/bg-modal.png";

const CustomBody = styled(Box)(({ theme }) => ({
  maxWidth: "528px",
  minHeight: "235px",
  borderRadius: "10px",
  backgroundImage: `url(${bgModal})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

export default function ModalSubmit(props) {
  const { t } = useTranslation();
  const { setOpen, title } = props;

  return (
    <CustomBody className="flex items-center justify-center flex-col text-center m-auto bg-white px-2 pb-2 pt-5">
      <Typo variant="h3" className="!font-thasadith">
        {title}
      </Typo>
    </CustomBody>
  );
}
