import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// ROUTE
import { routePath } from '../../configs/route';

// COMPONENT
import { Typo } from '../../assets/styles/typography';
import PrimaryHeader from '../header/PrimaryHeader';
import ButtonPrimary from '../button/Primary';
import Footer from '../footer/Footer';

// STYLE
import useStyles from '../../assets/styles';

export default function History() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box
      className={`${classes.containerLayout} ${classes.mainLayout} ${classes.mainBackground} min-h-screen xxl:min-h-fit flex flex-col justify-center pb-7`}
    >
      <Box className={`${classes.container} mt-6`}>
        <PrimaryHeader title={t('HOME.SECTION_HISTORY.TITLE')} />
      </Box>
      <Box className={'max-w-[990px] mx-auto mt-6 text-justify'}>
        <Typo variant="h5" className={`indent-12 !text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_1')}
        </Typo>
        <Typo variant="h5" className={`indent-12 !text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_2')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl py-4`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.TITLE')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_1')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_2')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_3')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_4')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_5')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_6')}
        </Typo>
        <Typo variant="h5" className={`!text-xl lg:!text-2xl`}>
          {t('HOME.SECTION_HISTORY.BODY.PARAGRAPH_3.LIST_7')}
        </Typo>
      </Box>
      <Box className="text-center mt-12 mb-8 lg:mt-8">
        <ButtonPrimary
          label={t('BUTTON.VIEW_MORE')}
          onClick={() => {
            navigate(routePath.history);
          }}
          classes={'lg:!text-2xl'}
          style={{ minWidth: '265px' }}
        />
      </Box>
      <Footer className={`mt-8 lg:mt-auto`} />
    </Box>
  );
}
