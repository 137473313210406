import React from 'react';
import { Box } from '@mui/material';

// TRANSLATION
import { useTranslation } from 'react-i18next';

// COMPONENT
import ButtonText from './Text';

export default function Translation() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('doitung_locale', lang);
  };

  return (
    <Box className={'flex items-center'}>
      <ButtonText
        label={'TH'}
        onClick={() => handleChangeLanguage('th')}
        fontSize={'!text-base lg:!text-xl'}
      />
      <span className={'px-1 text-sm lg:text-xl'}>|</span>
      <ButtonText
        label={'EN'}
        onClick={() => handleChangeLanguage('en')}
        fontSize={'!text-base lg:!text-xl'}
      />
    </Box>
  );
}
