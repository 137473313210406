// import red from '@material-ui/core/colors/red';

const palette = {
  white: {
    default: '#FFFFFF',
    80: 'rgba(255, 255, 255, 0.80)',
  },
  black: {
    25: 'rgba(0, 0, 0, 0.25)',
    40: 'rgba(0, 0, 0, 0.40)',
  },
  nav: '#F4ECE0',
  navy: '#474859',
  brown: {
    100: '#F5EDE4',
    200: '#FCE6CD',
    300: '#EAD1BA',
    400: '#DDBD9B',
    600: '#7E654E',
    700: '#B7952D',
    800: '#9D8D71',
    900: '#554324',
  },
  blue: '#A0CCFF',
  green: '#BFFFA0',
  red: '#FFBEBE',
  dark: {
    200: '#E2E1E1',
    700: '#6A6A6A',
    800: '#3A3A3A',
    900: '#323239',
  },
};

export default palette;
