import axios from "../configs/axios";

export const loginAdmin = async ({ username, password }) => {
  try {
    const result = await axios.post("/admin/login", {
      username,
      password,
    });
    return result.data;
  } catch (err) {
    console.log(err);
  }
};
